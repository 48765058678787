import React from "react";
import SplashScreenLogoRaw from "../assets/logo_splash_screen.svg";
import { globalStyleColors } from "./GlobalStyles";

export const splashScreenLogoConstants = {
  width: 225,
  height: 71,
  color: globalStyleColors.blackSoft,
};

export const SplashScreenLogo = React.memo(() => {
  return (
    <SplashScreenLogoRaw
      width={splashScreenLogoConstants.width}
      height={splashScreenLogoConstants.height}
      color={splashScreenLogoConstants.color}
    />
  );
});
