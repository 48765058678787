import { useState } from "react";
import { withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { TBody } from "../components/Typography";
import { View } from "react-native";
import { ButtonRectangle } from "../components/Buttons";
import { useCallback } from "react";
import { ContainerEnd, ContainerPadded } from "../components/Containers";
import { Spacer } from "../components/Spacer";
import { useDispatch } from "../lib/redux/Redux";
import { disableDiagnosticMode, enableDiagnosticMode } from "../lib/system/SystemThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { useIsDiagnosticModeEnabled } from "../lib/system/SystemSelectors";
import { Separator } from "../components/Separator";
import { SupportInstructions } from "../components/SupportComponents";

const strings = {
  title: "Support",
  diagnosticMode:
    "Diagnostic mode helps us find and fix bugs if you're experienciencing unexpected behavior. It may cause some minor" +
    " performance issues (lagginess, etc.) Please only enable if you are instructed to by support.",
  enableDiagnosticMode: "Enable Diagnostic Mode",
  disableDiagnosticMode: "Disable Diagnostic Mode",
};

export const SupportScreen = withScreenContainer("SupportScreen", () => {
  const diagnosticModeEnabled = useIsDiagnosticModeEnabled();
  const dispatch = useDispatch();
  const [waiting, setWaiting] = useState(false);

  const toggleDiagnosticMode = useCallback(() => {
    if (diagnosticModeEnabled) {
      dispatch(disableDiagnosticMode(setWaiting)).catch(err => {
        displayUnexpectedErrorAndLog("Unexpected error disabling diagnostic mode", err);
      });
    } else {
      dispatch(enableDiagnosticMode(setWaiting)).catch(err => {
        displayUnexpectedErrorAndLog("Unexpected error enabling diagnostic mode", err);
      });
    }
  }, [diagnosticModeEnabled, dispatch]);

  const buttonTitle = diagnosticModeEnabled ? strings.disableDiagnosticMode : strings.enableDiagnosticMode;

  return (
    <ScreenView header={{ type: "default", title: strings.title, style: "default" }}>
      <View style={{ flex: 1 }}>
        <ContainerPadded all={1}>
          <SupportInstructions />
        </ContainerPadded>

        <ContainerEnd vertical>
          <Separator orientation="row" />

          <ContainerPadded all={1}>
            <TBody>{strings.diagnosticMode}</TBody>
            <Spacer vertical={2} />
            <ButtonRectangle
              onPress={toggleDiagnosticMode}
              title={buttonTitle}
              type="submit"
              waiting={waiting}
              singlePress={false}
              size="large"
            />
          </ContainerPadded>
        </ContainerEnd>
      </View>
    </ScreenView>
  );
});
