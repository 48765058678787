import { useCallback, useEffect, useMemo, useRef } from "react";
import { AppRecipe, haveRecipeIngredients } from "@eatbetter/recipes-shared";
import { useDispatch } from "../redux/Redux";
import { ReaderModeEnabledInstanceId, setReaderMode } from "./RecipesSlice";
import { useIsReaderModeEnabled, useRecipeSourceType } from "./RecipesSelectors";
import { useCheckpointCompleted } from "../system/SystemSelectors";
import { ReaderModeSpotlightContext, useReaderModeToggle } from "../../components/ReaderModeToggle";
import { reportRecipeTextViewToggled } from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";
import { log } from "../../Log";
import { usePaywallStatus } from "../../components/PaywallDetector";
import { newId, switchReturn } from "@eatbetter/common-shared";

export type ReaderModeContext = "library" | "search" | "post" | "share";

export function useReaderMode(
  recipe: AppRecipe | undefined,
  context: ReaderModeContext
): {
  readerModeEnabled: boolean;
  setReaderModeEnabled: (value: boolean) => void;
  toggleReaderModeEnabled: () => void;
  renderReaderModeToggle: () => React.ReactElement;
} {
  const dispatch = useDispatch();
  const readerModeToggledCheckpoint = useCheckpointCompleted("recipeReaderModeToggled");

  const instanceId = useRef<ReaderModeEnabledInstanceId>(newId()).current;
  const readerModeEnabled = useIsReaderModeEnabled(instanceId);
  const lastReaderModeEnabled = useRef(readerModeEnabled);

  const recipeId = recipe?.id;
  const haveIngredients = !!recipe && haveRecipeIngredients(recipe.ingredients);
  const isWebRecipe = useRecipeSourceType(recipe) === "url";
  const { paywallStatus, paywallIsUp } = usePaywallStatus(recipe);

  const setReaderModeEnabled = useCallback(
    (value: boolean) => {
      if (!recipeId) {
        log.error("useReaderMode.setReaderModeEnabled called but recipeId is falsy", {
          recipeId: recipe?.id,
          setReaderModeToValue: value,
          instanceId,
        });
        return;
      }
      dispatch(setReaderMode({ instanceId, recipeId: recipe.id, isEnabled: value }));
    },
    [dispatch, instanceId, recipeId]
  );

  const toggleReaderModeEnabled = useCallback(() => {
    setReaderModeEnabled(!readerModeEnabled);
  }, [setReaderModeEnabled, readerModeEnabled]);

  // Disable reader mode on unmount
  useEffect(() => {
    if (!recipeId) {
      return;
    }
    return () => setReaderModeEnabled(false);
  }, [recipeId]);

  // Analytics: report reader mode toggle
  useEffect(() => {
    if (!recipe) {
      return;
    }

    if (readerModeEnabled !== lastReaderModeEnabled.current) {
      const event = reportRecipeTextViewToggled({
        recipe,
        textView: readerModeEnabled,
        paywallStatus,
      });
      dispatch(analyticsEvent(event));
      lastReaderModeEnabled.current = readerModeEnabled;
    }
  }, [readerModeEnabled, recipe]);

  const disableReaderModeToggle = !haveIngredients;
  const showReaderModeToggleSpotlight = !readerModeToggledCheckpoint && isWebRecipe && !paywallIsUp && haveIngredients;

  const spotlightContext = switchReturn<ReaderModeContext, ReaderModeSpotlightContext>(context, {
    library: "spotlightLibraryReaderMode",
    post: "spotlightPostViewRecipeReaderMode",
    share: "spotlightShareViewRecipeReaderMode",
    search: "spotlightSearchViewRecipeReaderMode",
  });

  const readerModeToggleElement = useReaderModeToggle(
    spotlightContext,
    readerModeEnabled,
    toggleReaderModeEnabled,
    disableReaderModeToggle,
    showReaderModeToggleSpotlight
  );

  const renderReaderModeToggle = useCallback(() => {
    return <>{isWebRecipe && readerModeToggleElement}</>;
  }, [isWebRecipe, readerModeToggleElement]);

  return useMemo(
    () => ({
      readerModeEnabled,
      setReaderModeEnabled,
      toggleReaderModeEnabled,
      readerModeToggledCheckpoint,
      renderReaderModeToggle,
    }),
    [
      readerModeEnabled,
      setReaderModeEnabled,
      toggleReaderModeEnabled,
      readerModeToggledCheckpoint,
      renderReaderModeToggle,
    ]
  );
}
