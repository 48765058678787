import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { Photo } from "../Photo";
import { Spacer } from "../Spacer";
import { TSecondary } from "../Typography";
import { UrlString, UserId, Username, switchReturn } from "@eatbetter/common-shared";
import { Separator } from "../Separator";
import { Pressable } from "../Pressable";
import { globalStyleConstants } from "../GlobalStyles";
import { PhotoRef } from "@eatbetter/photos-shared";
import { ButtonRectangle, FollowEntityButton } from "../Buttons";
import { useIsAnonymousUser, useSystemSetting } from "../../lib/system/SystemSelectors";
import { smallScreenBreakpoint } from "../Responsive";
import { ProfileLinks } from "./ProfileLinks";
import { navToAnonymousSignin } from "../../lib/util/AnonymousSignIn";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { useDispatch } from "../../lib/redux/Redux";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportProfileInviteFriendsButtonPressed } from "../../lib/analytics/AnalyticsEvents";
import { KnownAuthorId, KnownPublisherId } from "@eatbetter/recipes-shared";
import { shareAuthedUserProfileLink } from "../../lib/share/ShareThunks";

const strings = {
  yourName: "Your name",
  yourUsername: "Your username",
  editProfile: "Edit",
  editProfileAction: "edit your profile",
  inviteFriends: "Invite friends",
  followers: "Followers",
  recipes: "Recipes",
  cooks: "Cooks",
  following: "Following",
  inviteFriendsAction: "invite friends",
};

interface ProfileCardInternalProps {
  entityId?: KnownAuthorId | KnownPublisherId;
  otherUserId?: UserId;
  photo?: PhotoRef;
  bannerPhoto?: PhotoRef;
  primaryStats?: PrimaryStat[];
  username?: Username;
  name?: string;
  profileBio?: string;
  profileLinks?: {
    links?: UrlString[];
    instagramHandle?: string;
    substackLink?: UrlString;
  };
}

export const ProfileCard = React.memo((props: ProfileCardInternalProps) => {
  const entityId = props.entityId;
  const otherUserId = props.otherUserId;
  const photo = props.photo;
  const statsOrBannerPhoto = props.bannerPhoto ?? props.primaryStats;
  const username = props.username ?? strings.yourUsername;
  const name = props.name ?? strings.yourName;
  const profileBio = props.profileBio;
  const profileLinks = props.profileLinks;

  const followersEnabled = !!useSystemSetting("followers");

  return (
    <View
      style={{
        paddingHorizontal: globalStyleConstants.defaultPadding,
        paddingTop: globalStyleConstants.defaultPadding,
      }}
    >
      <PhotoAndStatsOrBanner userPhoto={photo} statsOrBannerPhoto={statsOrBannerPhoto} />
      <Spacer vertical={1} />
      <UsernameAndName entityId={entityId} username={username} name={name} />
      {!!profileBio && (
        <>
          <Spacer vertical={0.75} />
          <Bio text={profileBio} />
        </>
      )}
      {!!profileLinks && (
        <>
          <Spacer vertical={1} />
          <ProfileLinks
            links={profileLinks.links}
            instagramHandle={profileLinks.instagramHandle}
            substackLink={profileLinks.substackLink}
            otherUserId={otherUserId}
            entityId={entityId}
          />
          <Spacer vertical={0.5} />
        </>
      )}
      <Spacer vertical={1} />
      {!otherUserId && (
        <>
          <MyProfileActions />
          <Spacer vertical={globalStyleConstants.defaultPadding} unit="pixels" />
        </>
      )}
      {!!otherUserId && followersEnabled && (
        <>
          <OtherUserProfileActions userId={otherUserId} />
          <Spacer vertical={globalStyleConstants.defaultPadding} unit="pixels" />
        </>
      )}
      <Separator orientation="row" />
      <Spacer vertical={0.5} />
    </View>
  );
});

interface PrimaryStat {
  type: "recipes" | "cooks" | "following";
  value: number;
  onPress: () => void;
}

const PhotoAndStatsOrBanner = React.memo(
  (props: { userPhoto?: PhotoRef; statsOrBannerPhoto: PrimaryStat[] | PhotoRef | undefined }) => {
    const stats = Array.isArray(props.statsOrBannerPhoto) ? props.statsOrBannerPhoto : undefined;
    const bannerPhoto = !Array.isArray(props.statsOrBannerPhoto) ? props.statsOrBannerPhoto : undefined;

    return (
      <View style={{ flexDirection: "row", alignItems: "center", maxWidth: smallScreenBreakpoint }}>
        {!!bannerPhoto && (
          <>
            <View style={styles.bannerPhoto}>
              <Photo style="flexed" source={bannerPhoto} sourceSize="w1290" />
            </View>
            <View style={styles.bannerPhotoAvatarFrame} />
          </>
        )}
        <Photo style="avatarLarge" source={props.userPhoto} sourceSize="w288" />
        {!!stats && (
          <>
            <Spacer horizontal={1} />
            <PrimaryStats stats={stats} />
          </>
        )}
      </View>
    );
  }
);

const UsernameAndName = React.memo(
  (props: { entityId?: KnownAuthorId | KnownPublisherId; username: string; name: string }) => {
    if (props.entityId) {
      return <TSecondary fontWeight="medium">{props.name}</TSecondary>;
    }

    return (
      <>
        <TSecondary fontWeight="medium">{props.username}</TSecondary>
        <TSecondary opacity="medium">{props.name}</TSecondary>
      </>
    );
  }
);

const Bio = React.memo((props: { text: string }) => {
  return (
    <View>
      <TSecondary fixEmojiLineHeight>{props.text}</TSecondary>
    </View>
  );
});

const PrimaryStats = React.memo((props: { stats: PrimaryStat[] }) => {
  return (
    <View style={styles.primaryStats}>
      {props.stats.map(i => {
        const label = switchReturn(i.type, {
          recipes: strings.recipes,
          cooks: strings.cooks,
          following: strings.following,
        });

        return (
          <Pressable key={label} noFeedback style={{ alignItems: "center" }} onPress={i.onPress}>
            <TSecondary>{label}</TSecondary>
            <TSecondary fontWeight="medium">{i.value.toLocaleString()}</TSecondary>
          </Pressable>
        );
      })}
    </View>
  );
});

const MyProfileActions = React.memo(() => {
  const isAnonUser = useIsAnonymousUser();
  const screen = useScreen();

  const onPressEditProfile = useCallback(() => {
    if (isAnonUser) {
      navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.editProfileAction });
      return;
    }

    screen.nav.goTo("push", navTree.get.screens.profileEdit);
  }, [screen.nav.goTo, isAnonUser]);

  return (
    <View style={{ flexDirection: "row" }}>
      <View style={{ flex: 2 }}>
        <InviteFriendsButton text={strings.inviteFriends} />
      </View>
      <Spacer horizontal={globalStyleConstants.minPadding} unit="pixels" />
      <View style={{ flex: 1 }}>
        <EditProfileButton text={strings.editProfile} onPress={onPressEditProfile} />
      </View>
    </View>
  );
});

const OtherUserProfileActions = React.memo((props: { userId: UserId }) => {
  return <FollowEntityButton entityId={props.userId} context="otherUserProfile" />;
});

const InviteFriendsButton = React.memo((props: { text: string }) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const isAnonUser = useIsAnonymousUser();

  const onPressInviteFriends = useCallback(async () => {
    dispatch(analyticsEvent(reportProfileInviteFriendsButtonPressed()));
    if (isAnonUser) {
      navToAnonymousSignin(screen.nav, { mode: "action", userVisibleActionDescription: strings.inviteFriends });
      return;
    }
    await dispatch(shareAuthedUserProfileLink("Profile Screen Invite Friends Button"));
  }, [dispatch, isAnonUser, screen.nav]);

  return <ActionButton type="cta" text={props.text} icon="addFriends" onPress={onPressInviteFriends} />;
});

const EditProfileButton = React.memo((props: { text: string; onPress: () => void }) => {
  return <ActionButton text={props.text} icon="edit" onPress={props.onPress} />;
});

const ActionButton = React.memo(
  (props: { type?: "cta" | "default"; text: string; icon?: "edit" | "addFriends"; onPress: () => void }) => {
    const buttonStyle = switchReturn(props.type ?? "default", {
      cta: "submit" as const,
      default: "secondary" as const,
    });

    return (
      <ButtonRectangle
        type={buttonStyle}
        size="small"
        icon={props.icon}
        title={props.text}
        onPress={props.onPress}
        singlePress
      />
    );
  }
);

const styles = StyleSheet.create({
  primaryStats: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  bannerPhoto: {
    position: "absolute",
    overflow: "hidden",
    width: "100%",
    height: 72,
    borderTopLeftRadius: 72,
    borderBottomLeftRadius: 72,
  },
  bannerPhotoAvatarFrame: {
    position: "absolute",
    overflow: "hidden",
    height: 84,
    width: 84,
    borderRadius: 84,
    backgroundColor: "white",
  },
});
