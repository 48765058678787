import { PropsWithChildren } from "react";
import { RootSiblingParent } from "react-native-root-siblings";
import { withErrorBoundary } from "./ErrorBoundary";

export const ToastProvider = withErrorBoundary(
  "ToastProvider",
  (props: PropsWithChildren<{}>) => {
    return <RootSiblingParent>{props.children}</RootSiblingParent>;
  },
  props => <>{props.children}</>
);
