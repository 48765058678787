import { useEffect } from "react";
import { View } from "react-native";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { useDispatch, useSelector } from "../lib/redux/Redux";
import { ScreenView } from "../components/ScreenView";
import { log } from "../Log";
import { navHome } from "../navigation/NavThunks";
import { bottomThrow } from "@eatbetter/common-shared";

export const LaunchScreen = withScreenContainer(
  "LaunchScreen",
  () => {
    const screen = useScreen();
    const authStatus = useSelector(s => s.system?.authStatus);
    const dispatch = useDispatch();

    // When the app is launched and we already have an auth status, this handles the navigation.
    // If the auth status resolves after (because the user signs in), a useEffect in ScreenContainer
    // handles the navigation.
    // Note that we disable auth status redirects in the container with the options below
    // IMPORTANT: Some of this is duplicated in SignIn.tsx and in ScreenContainer.tsx.
    // Logic changes here might need to be ported there (or refactor into a function).
    useEffect(() => {
      switch (authStatus) {
        case "signedIn":
        case "signedInNoAccount":
          dispatch(navHome(screen.nav, "LaunchScreen"));
          break;
        case "signedOut":
        case "signingOut": {
          log.info("Navigating to signInScreen from LaunchScreen");
          screen.nav.goTo("reset", navTree.get.screens.signIn);
          break;
        }
        case "pending":
          //noop
          break;
        default:
          bottomThrow(authStatus);
      }
    }, [authStatus]);

    return (
      <ScreenView>
        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }} />
      </ScreenView>
    );
  },
  undefined
);
