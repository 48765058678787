import {
  LinkNotificationPayload,
  Notification,
  NotificationOnboardingHelp,
  PushNotificationAppNavigation,
  ReceivedNotificationData,
  UserNotificationPushTypes,
} from "@eatbetter/users-shared";
import { SocialNotificationPushTypes } from "@eatbetter/posts-shared";
import { GroceryNotificationPushTypes } from "@eatbetter/lists-shared";
import { RecipesNotificationPushTypes, UserRecipeId } from "@eatbetter/recipes-shared";
import { singleNotificationTapped } from "./notifications/NotificationsThunks";
import { NavTree, navTree } from "../navigation/NavTree";
import { groceryListSortChanged } from "./lists/ListsSlice";
import { log } from "../Log";
import { bottomLog, DataAndType } from "@eatbetter/common-shared";
import { AppDispatch } from "./redux/Redux";
import { NavApi } from "../navigation/ScreenContainer";
import { CookingTimerId } from "./cooking/CookingSessionsSlice";
import { timerNotificationTapped } from "./cooking/CookingTimerThunks";
import { Linking } from "react-native";
import { getAndStoreUserRecipe } from "./recipes/RecipesThunks";
import { loadNewHomeFeedPosts, loadNewProfilePosts } from "./social/SocialThunks";
import { navToCookingSessionIfExists } from "../navigation/NavThunks";

export type TimerNotificationContext = DataAndType<"timers/timerComplete", { timerId: CookingTimerId }>;

export type NotificationContext =
  | TimerNotificationContext
  | UserNotificationPushTypes
  | SocialNotificationPushTypes
  | GroceryNotificationPushTypes
  | RecipesNotificationPushTypes;

export const handlePushNotificationTapped = (
  n: ReceivedNotificationData<NotificationContext>,
  dispatch: AppDispatch,
  switchTab: NavApi["switchTab"],
  modal: NavApi["modal"]
) => {
  // if we handle the notification by nav'ing, we should mark it as read in the notification center
  const markAsRead = () => {
    if (n.notificationIdempotencyId) {
      dispatch(singleNotificationTapped(n.notificationIdempotencyId, n, "OS"));
    }
  };

  switch (n.type) {
    case "recipes/recipeShared": {
      markAsRead();
      if (!dispatch(navToCookingSessionIfExists({ type: "share", recipeId: n.data.sourceRecipeId, nav: switchTab }))) {
        switchTab("homeTab", navTree.get.screens.shareViewRecipe, {
          sourceRecipeId: n.data.sourceRecipeId,
          sharedByRecipeId: n.data.sharedByRecipeId,
          sharedByUserId: n.data.sharedBy,
          context: "userShared" as const,
        });
      }
      break;
    }
    case "recipes/recipeUpdated": {
      handleRecipeUpdated(dispatch, switchTab, n.data.userRecipeId);
      break;
    }
    case "social/postRecipeSaved":
    case "social/postLiked": {
      markAsRead();
      switchTab("homeTab", navTree.get.screens.postDetail, { postId: n.data.postId });
      break;
    }
    case "social/newPost": {
      markAsRead();
      dispatch(loadNewHomeFeedPosts("followingFeed")).catch(err => {
        log.errorCaught("Error dispatching loadNewHomeFeedPosts in notification handler", err);
      });
      if (n.data.postType === "newRecipePost") {
        switchTab("homeTab", navTree.get.screens.postViewRecipe, { postId: n.data.postId });
      } else {
        switchTab("homeTab", navTree.get.screens.postDetail, { postId: n.data.postId });
      }
      break;
    }
    case "social/postReady": {
      markAsRead();
      // this notifiation is used when a user's own post is ready AND for the first cook notification
      // if a user saves a recipe and then cooks it. We don't differentiate the payload at all,
      // so always update the profile here.
      dispatch(loadNewProfilePosts()).catch(err => {
        log.errorCaught("Error dispatching loadNewProfilePosts", err);
      });
      switchTab("homeTab", navTree.get.screens.postDetail, { postId: n.data.postId });
      break;
    }
    //@ts-ignore-next-line Back compat for removed notification type
    case "social/postCommentMention":
    case "social/postComment": {
      markAsRead();
      switchTab("homeTab", navTree.get.screens.postComments, { postId: n.data.postId, focusInput: false });
      break;
    }
    case "social/newFollower": {
      markAsRead();
      switchTab("homeTab", navTree.get.screens.otherUserProfile, { userId: n.data.userId });
      break;
    }
    case "lists/recipeAdded":
    case "lists/recipeShopped":
      markAsRead();
      dispatch(groceryListSortChanged("recipe"));
      switchTab("groceriesTab", navTree.get.screens.groceryList);
      break;
    case "timers/timerComplete": {
      // see ScreenContainer for a hack related to this flow
      dispatch(timerNotificationTapped(n.data.timerId));
      break;
    }
    case "users/householdMemberAdded":
      // there isn't a great place to go for this, so just open the notifications screen
      switchTab("homeTab", navTree.get.screens.notificationCenter);
      break;
    case "users/externalLink": {
      markAsRead();
      openExternalUrl(n.data);
      break;
    }
    case "users/videoLink": {
      markAsRead();
      modal(navTree.get.screens.viewVideo, {
        videoSource: n.data.url,
        analyticsId: n.data.analyticsAndIdempotencyDescription,
      });
      break;
    }
    case "test/send-push-notification":
      log.info("Test notification tapped");
      break;
    case "users/onboardingPrompt":
      handleOnboarding(n, switchTab, modal, dispatch);
      break;
    case "users/appNavigation":
      handleNav(n, switchTab);
      break;
    default:
      bottomLog(n, "handlePushNotificationTapped NotificationContext.type");
  }
};

export const handleNotificationCenterItemTapped = (n: Notification, dispatch: AppDispatch, nav: NavApi) => {
  const context = n.context as NotificationContext;
  dispatch(singleNotificationTapped(n.idempotencyId, context, "App Notification Center"));
  switch (context.type) {
    case "recipes/recipeShared":
      if (
        !dispatch(
          navToCookingSessionIfExists({ type: "share", recipeId: context.data.sourceRecipeId, nav: nav.switchTab })
        )
      ) {
        nav.goTo("push", navTree.get.screens.shareViewRecipe, {
          sourceRecipeId: context.data.sourceRecipeId,
          sharedByRecipeId: context.data.sharedByRecipeId,
          sharedByUserId: context.data.sharedBy,
          context: "userShared" as const,
        });
      }
      break;
    case "recipes/recipeUpdated":
      handleRecipeUpdated(dispatch, nav.switchTab, context.data.userRecipeId);
      break;
    case "social/postLiked":
    case "social/postRecipeSaved":
    case "social/postReady":
      nav.goTo("push", navTree.get.screens.postDetail, { postId: context.data.postId });
      break;
    case "social/newPost":
      if (context.data.postType === "newRecipePost") {
        nav.goTo("push", navTree.get.screens.postViewRecipe, { postId: context.data.postId });
      } else {
        nav.goTo("push", navTree.get.screens.postDetail, { postId: context.data.postId });
      }
      break;
    case "social/postComment":
      nav.goTo("push", navTree.get.screens.postComments, { postId: context.data.postId, focusInput: false });
      break;
    case "social/newFollower":
      nav.goTo("push", navTree.get.screens.otherUserProfile, { userId: context.data.userId });
      break;
    case "lists/recipeAdded":
    case "lists/recipeShopped":
      dispatch(groceryListSortChanged("recipe"));
      nav.switchTab("groceriesTab", navTree.get.screens.groceryList);
      break;
    case "timers/timerComplete":
      // NOP
      break;
    case "users/householdMemberAdded":
      nav.switchTab("profileTab", navTree.get.screens.householdManagement);
      break;
    case "users/externalLink":
      openExternalUrl(context.data);
      break;
    case "users/videoLink":
      nav.modal(navTree.get.screens.viewVideo, {
        videoSource: context.data.url,
        analyticsId: context.data.analyticsAndIdempotencyDescription,
      });
      break;
    case "test/send-push-notification":
      log.info("Test notification tapped");
      break;
    case "users/onboardingPrompt":
      handleOnboarding(context, nav.switchTab, nav.modal, dispatch);
      break;
    case "users/appNavigation":
      handleNav(context, nav.switchTab);
      break;
    default:
      bottomLog(context, "handleNotificationCenterItemTapped NotificationContext.type");
      break;
  }
};

function handleNav(context: PushNotificationAppNavigation, switchTab: NavApi["switchTab"]) {
  const screen = navTree.get.screens[context.data.screen as keyof NavTree["screens"]];
  if (!screen) {
    log.error(`Received PushNotificationAppNavigation but couldn't find screen ${context.data.screen}`, { context });
    return;
  }

  switchTab(context.data.tabName, screen as any, context.data.params as any);
}

function handleOnboarding(
  context: NotificationOnboardingHelp,
  switchTab: NavApi["switchTab"],
  modal: NavApi["modal"],
  _dispatch: AppDispatch
) {
  switch (context.data.prompt) {
    case "addWebRecipe":
      // dispatch(highlightLibraryAddButtonChanged(true));
      // switchTab("recipesTab", navTree.get.screens.recipesHome);
      modal(navTree.get.screens.onboardShareExtension);
      break;
    case "setUpHousehold":
      switchTab("profileTab", navTree.get.screens.householdManagement);
      break;
    case "howToPost":
      modal(navTree.get.screens.onboardSocial);
      break;
    case "homeTab":
      switchTab("homeTab", navTree.get.screens.home);
      break;
    case "recipesTab":
      switchTab("recipesTab", navTree.get.screens.recipesHome);
      break;
    case "groceriesTab":
      switchTab("groceriesTab", navTree.get.screens.groceryList);
      break;
    case "profileTab":
      switchTab("profileTab", navTree.get.screens.profile);
      break;
    default:
      bottomLog(context.data.prompt, "handleNotificationCenterItemTapped onboardingPrompt");
  }
}

function handleRecipeUpdated(dispatch: AppDispatch, switchTab: NavApi["switchTab"], recipeId: UserRecipeId) {
  dispatch(getAndStoreUserRecipe({ recipeId })).catch(err =>
    log.errorCaught("Error dispatching getAndStoreUserRecipe from NotificationHandler", err)
  );

  switchTab("recipesTab", navTree.get.screens.recipeDetail, { recipeId });
}

function openExternalUrl(payload: LinkNotificationPayload) {
  Linking.openURL(payload.url).catch(err => {
    log.errorCaught(`Unexpected error attempting to open "${payload.url}"`, err);
  });
}
