import { withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { StyleSheet, View } from "react-native";
import { SignIn } from "../components/SignIn";
import { globalStyleColors, globalStyleConstants } from "../components/GlobalStyles";
import { SignInScreenProps } from "../navigation/NavTree";
import { tryParseBool } from "@eatbetter/common-shared";
import { useMemo } from "react";
import { HeaderProps } from "../components/ScreenHeaders";
import { DocumentHeadEditProps } from "../components/DocumentHeadEdit/DocumentHeadInterfaces";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { TBody } from "../components/Typography";
import { SplashScreenLogo, splashScreenLogoConstants } from "../components/SplashScreenLogo";
import { ContainerFadeIn } from "../components/Containers";

const strings = {
  tagline: "Cooking, simplified",
};

const backgroundColor = globalStyleColors.colorAccentMidDark;

export const SignInScreen = withScreenContainer(
  "SignInScreen",
  (props: SignInScreenProps) => {
    const header = useMemo<HeaderProps | undefined>(() => {
      if (props.showBackButton) {
        return {
          type: "default",
          title: "",
          backgroundColor: "transparent",
        };
      }

      return undefined;
    }, [props.showBackButton]);

    const webMeta = useMemo<DocumentHeadEditProps>(() => {
      return {
        themeColor: backgroundColor,
      };
    }, []);

    const { bottomTabBarHeight } = useBottomTabBarDimensions();
    const { top: safeAreaTop } = useSafeAreaInsets();

    // This coordinates with the splash screen. If positioning changes here, it must change there as well.
    const top = safeAreaTop + 103;

    const paddingBottom =
      bottomTabBarHeight > 0 ? bottomTabBarHeight + globalStyleConstants.unitSize : 2 * globalStyleConstants.unitSize;

    return (
      <ScreenView
        paddingHorizontal={false}
        paddingVertical={false}
        scrollView={false}
        header={header}
        webMeta={webMeta}
        backgroundColor={backgroundColor}
      >
        <View style={[styles.container, { marginBottom: paddingBottom }]}>
          <View style={[styles.logoContainer, { top }]}>
            <SplashScreenLogo />
            <Tagline />
          </View>
          <SignIn redirectPath={props.redirectPath} allowAnonymousIfAvailable />
        </View>
      </ScreenView>
    );
  },
  {
    parser: {
      redirectPath: { optional: true, fn: s => s },
      showBackButton: { optional: true, fn: s => tryParseBool(s) ?? false },
    },
    serializer: {
      redirectPath: { optional: true, fn: s => s },
      showBackButton: { optional: true, fn: s => (s ? "1" : "0") },
    },
    nameRemapping: {
      redirectPath: "r",
      showBackButton: "b",
    },
  }
);

const Tagline = () => {
  return (
    <ContainerFadeIn style={styles.tagline} delay={500} duration={1000}>
      <TBody fontWeight="normal" color={globalStyleColors.blackSoft} align="center">
        {strings.tagline}
      </TBody>
    </ContainerFadeIn>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    paddingHorizontal: 20,
  },
  logoContainer: {
    position: "absolute",
    alignSelf: "center",
  },
  tagline: {
    marginTop: globalStyleConstants.unitSize,
    minWidth: splashScreenLogoConstants.width,
    maxWidth: splashScreenLogoConstants.width,
  },
});
