import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

export const smallScreenBreakpoint = 640;
export const largeScreenBreakpoint = 1200;

export function useResponsiveDimensions() {
  const window = useWindowDimensions();

  return useMemo(() => {
    return {
      ...window,
      isLargeScreen: window.width >= largeScreenBreakpoint,
      isMediumScreen: window.width > smallScreenBreakpoint && window.width < largeScreenBreakpoint,
      isSmallScreen: window.width <= smallScreenBreakpoint,
    };
  }, [window]);
}
