import { bottomNop, emptyToUndefined, getFormattedDuration } from "@eatbetter/common-shared";
import {
  Author,
  Book,
  Publisher,
  RecipeIngredients,
  RecipeInstructions,
  RecipeSource,
  RecipeTime,
  RecipeYield,
  AppRecipe,
} from "@eatbetter/recipes-shared";
import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { ContainerPadded } from "../Containers";
import { Spacer } from "../Spacer";
import { THeading2, TSecondary } from "../Typography";
import { RecipeSectionHeading } from "./RecipeSectionHeading";
import { RecipeAuthor, RecipePublisherOrBook, RecipeTitle } from "./RecipeTitleAndSource";
import { Separator } from "../Separator";
import { BuyBookButton } from "../Buttons";
import { recipeHasUsablePhoto, RecipePhoto } from "../RecipePhoto";
import { globalStyleConstants } from "../GlobalStyles";
import { smallScreenBreakpoint, useResponsiveDimensions } from "../Responsive";
import { Haptics } from "../Haptics";
import { usePaywallStatus } from "../PaywallDetector";
import { useRecipeInstructionsAccess } from "../../lib/recipes/UseRecipeInstructionsAccess";
import { HiddenWebInstructionsMessage } from "./HiddenInstructionsMessage";

const strings = {
  ingredients: "Ingredients",
  instructions: "Instructions",
  noIngredients: "(No ingredients)",
  noInstructions: "(No instructions)",
  recipeYield: "Yield",
  totalTime: "Total Time",
  activeTime: "Active Time",
};

export const RecipeContent = React.memo((props: { recipe: AppRecipe; toggleReaderMode?: () => void }) => {
  const publisher = props.recipe.publisher;
  const author = props.recipe.author;
  const book = props.recipe.book;
  const title = props.recipe.title;
  const source = props.recipe.source;
  const bookPurchaseLink = props.recipe.book?.purchaseLink;
  const ingredients = props.recipe.ingredients;

  const instructionsAccess = useRecipeInstructionsAccess(props.recipe);
  const { paywallIsUp } = usePaywallStatus(props.recipe);

  // if there's a paywall, don't render description
  const showDescription = emptyToUndefined(props.recipe.description) !== undefined && !paywallIsUp;
  const description = showDescription ? props.recipe.description : undefined;

  const onPressPublisherLink = useCallback(() => {
    if (props.toggleReaderMode) {
      props.toggleReaderMode();
      Haptics.feedback("itemStatusChanged");
    }
  }, [props.toggleReaderMode]);

  const getInstructionsAndEmptyMessage = () => {
    switch (instructionsAccess.type) {
      case "show":
        return { instructions: props.recipe.instructions, emptyMessage: strings.noInstructions };
      case "hide":
        return {
          instructions: undefined,
          emptyMessage: (
            <HiddenWebInstructionsMessage
              context="xRay"
              hiddenReason={instructionsAccess.reason}
              onPressLink={onPressPublisherLink}
              publisherName={publisher?.name}
            />
          ),
        };
      default: {
        bottomNop(instructionsAccess);
        return { instructions: props.recipe.instructions, emptyMessage: strings.noInstructions };
      }
    }
  };

  const { instructions: recipeInstructions, emptyMessage: instructionsEmptyMessage } = getInstructionsAndEmptyMessage();

  const showBuyBookButton = source.type === "book" && !!bookPurchaseLink;

  const hasPhoto = recipeHasUsablePhoto(props.recipe);
  const dimensions = useResponsiveDimensions();
  const photoHeight =
    dimensions.width > smallScreenBreakpoint ? styles.recipePhotoHeightLarge : styles.recipePhotoHeight;

  return (
    <ContainerPadded all={8 / globalStyleConstants.unitSize}>
      {hasPhoto && (
        <View style={[styles.recipePhoto, photoHeight]}>
          <RecipePhoto recipe={props.recipe} tapToExpand sourceSize="w1290" />
        </View>
      )}
      <Spacer vertical={1.5} />
      <ContainerPadded horizontal={1} bottom={3}>
        <RecipeTitleAndSource title={title} publisher={publisher} book={book} author={author} source={source} />
        {showBuyBookButton && (
          <>
            <Spacer vertical={1} />
            <BuyBookButton recipe={props.recipe} bookPurchaseLink={bookPurchaseLink} />
          </>
        )}
        {!!description && (
          <>
            <Spacer vertical={1.5} />
            <TSecondary>{description}</TSecondary>
          </>
        )}
        {(props.recipe.recipeYield || props.recipe.time?.total) && (
          <>
            <Spacer vertical={1.5} />
            <Separator orientation="row" />
            <Spacer vertical={1.5} />
            <RecipeTimeAndYield recipeTime={props.recipe.time} recipeYield={props.recipe.recipeYield} />
          </>
        )}
        <Spacer vertical={1.5} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <THeading2>{strings.ingredients}</THeading2>
        <Spacer vertical={1.5} />
        <RecipeIngredientsComponent ingredients={ingredients} />
        <Spacer vertical={1.5} />
        <Separator orientation="row" />
        <Spacer vertical={2} />
        <THeading2>{strings.instructions}</THeading2>
        <Spacer vertical={1.5} />
        <RecipeInstructionsComponent instructions={recipeInstructions} emptyMessage={instructionsEmptyMessage} />
      </ContainerPadded>
    </ContainerPadded>
  );
});

const RecipeTitleAndSource = React.memo(
  (props: { title: string; source: RecipeSource; publisher?: Publisher; book?: Book; author?: Author }) => {
    const publisherOrBook = props.book ?? props.publisher;
    const onPressAuthor = props.author?.type === "userAuthor" ? "navToProfile" : undefined;

    return (
      <>
        <RecipeTitle title={props.title} fontSize="h1" numberOfLines="unlimited" />
        <Spacer vertical={1} />
        {!!props.author && (
          <RecipeAuthor
            author={props.author}
            fontSize="secondary"
            numberOfLines="unlimited"
            onPressAction={onPressAuthor}
            tapEnabled
          />
        )}
        <Spacer vertical={1} />
        {!!publisherOrBook && (
          <RecipePublisherOrBook
            author={props.author}
            book={props.book}
            publisher={props.publisher}
            showFromPrefix
            fontSize="secondary"
            numberOfLines="unlimited"
            photoStyle="square"
            tapEnabled
          />
        )}
      </>
    );
  }
);

const RecipeIngredientsComponent = React.memo((props: { ingredients: RecipeIngredients | undefined }) => {
  const ingredients = props.ingredients;

  if (!ingredients) {
    return <IngredientOrInstructionItem text={strings.noIngredients} index={0} />;
  }

  return (
    <>
      {ingredients.sections.map((section, idx) => (
        <View key={section.id}>
          <ContainerPadded top={idx !== 0 ? 1 : 0} bottom={section.title || idx !== 0 ? 1 : 0}>
            <RecipeSectionHeading key={section.id} size="secondary" sectionIndex={idx} text={section.title} />
          </ContainerPadded>
          {section.items.map((i, idx) => (
            <IngredientOrInstructionItem key={i.id} text={i.text} index={idx} />
          ))}
        </View>
      ))}
    </>
  );
});

const RecipeInstructionsComponent = React.memo(
  (props: { instructions: RecipeInstructions | undefined; emptyMessage: string | React.ReactElement }) => {
    if (!props.instructions || props.instructions.sections.length === 0) {
      return <IngredientOrInstructionItem text={props.emptyMessage} index={0} />;
    }

    return (
      <>
        {props.instructions.sections.map((section, idx) => (
          <View key={section.id}>
            <ContainerPadded top={idx !== 0 ? 1 : 0} bottom={section.title || idx !== 0 ? 1 : 0}>
              <RecipeSectionHeading key={section.id} size="secondary" sectionIndex={idx} text={section.title} />
            </ContainerPadded>
            {section.items.map((i, idx) => (
              <IngredientOrInstructionItem key={i.id} text={i.text} index={idx} />
            ))}
          </View>
        ))}
      </>
    );
  }
);

const IngredientOrInstructionItem = React.memo((props: { text: string | React.ReactElement; index: number }) => (
  <View>
    {props.index !== 0 && <Spacer vertical={1} />}
    {typeof props.text === "string" && <TSecondary>{props.text}</TSecondary>}
    {typeof props.text !== "string" && props.text}
  </View>
));

const RecipeTimeAndYield = React.memo(
  (props: { recipeTime: RecipeTime | undefined; recipeYield: RecipeYield | undefined }) => {
    return (
      <View>
        {!!props.recipeTime && (
          <View>
            <TSecondary>{`${strings.totalTime}: ${getFormattedDuration(
              { milliseconds: props.recipeTime.total[0] },
              false,
              "XXh:XXm"
            )}`}</TSecondary>
          </View>
        )}
        {!!props.recipeTime && !!props.recipeYield && <Spacer vertical={1} />}
        {!!props.recipeYield && (
          <>
            <View>
              <TSecondary>{`${strings.recipeYield}: ${props.recipeYield.text}`}</TSecondary>
            </View>
          </>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  recipePhoto: {
    width: "100%",
    borderRadius: 20,
    overflow: "hidden",
  },
  recipePhotoHeight: {
    height: 256,
  },
  recipePhotoHeightLarge: {
    height: 464,
  },
});
