import React, { useCallback, useMemo } from "react";
import { OnScrollWorklet, SocialFeed, SocialFeedImperativeHandle } from "./SocialFeed";
import { followingFeed } from "../../lib/social/SocialSlice";
import { InviteFriends } from "./InviteFriends";
import { useDispatch } from "../../lib/redux/Redux";
import { loadNewHomeFeedPosts, loadOlderHomeFeedPosts } from "../../lib/social/SocialThunks";
import { log } from "../../Log";
import { usePostOptionsMenu } from "./SocialPost";
import { RecommendedFollows } from "./RecommendedFollows";
import { RecommendedPosts } from "./RecommendedPosts";
import { bottomThrow } from "@eatbetter/common-shared";

interface InlineModule {
  type: "recommendedFollows" | "inviteFriends";
  show: boolean;
}

export interface FollowingFeedProps {
  listHeaderComponent?: React.ReactElement;
  feedHeader?: "recommendedFollows";
  feedInlinedModules: InlineModule[];
  onScroll: OnScrollWorklet;
  onEndDrag?: OnScrollWorklet;
  onMomentumEnd?: OnScrollWorklet;
  goToExploreFeed: () => void;
  headerHeightScrolled: number;
}

export const FollowingFeed = React.memo(
  React.forwardRef<SocialFeedImperativeHandle, FollowingFeedProps>((props, ref) => {
    const dispatch = useDispatch();

    const followingFeedEmptyState = useMemo(() => <InviteFriends context="emptyFeed" />, []);

    const onPressOptionsMenuButton = usePostOptionsMenu();

    const onEndReached = useCallback(() => {
      // fail silently here - the user will likely just try to scroll again. Displaying an error
      // seems like overkill.
      dispatch(loadOlderHomeFeedPosts("followingFeed")).catch(err =>
        log.errorCaught("Error dispatching loadOlderPosts for following feed", err)
      );
    }, [dispatch]);

    const onPullToRefresh = useCallback(() => {
      return Promise.all([
        dispatch(loadNewHomeFeedPosts("followingFeed")).catch(err =>
          log.errorCaught("Error calling loadNewPosts for following feed in pull to refresh", err)
        ),
      ]);
    }, [dispatch]);

    const headerComponent = useMemo(() => {
      return (
        <>
          {props.listHeaderComponent}
          {props.feedHeader === "recommendedFollows" && <RecommendedFollows context="topSlotFeed" />}
        </>
      );
    }, [props.listHeaderComponent, props.feedHeader]);

    const footerComponent = useMemo(() => {
      return <RecommendedPosts type="exploreFeedTeaser" onPressViewMore={props.goToExploreFeed} />;
    }, [props.goToExploreFeed]);

    const inlinedModules = useMemo(() => {
      return props.feedInlinedModules.map(i => {
        switch (i.type) {
          case "inviteFriends": {
            return {
              key: i.type,
              renderModuleComponent: () => i.show && <InviteFriends context="inlineFeed" />,
            };
          }
          case "recommendedFollows": {
            return {
              key: i.type,
              renderModuleComponent: () => i.show && <RecommendedFollows context="inlineFeed" />,
            };
          }
          default:
            bottomThrow(i.type);
        }
      });
    }, [props.feedInlinedModules]);

    return (
      <SocialFeed
        ref={ref}
        feed={followingFeed}
        feedEmptyStateComponent={followingFeedEmptyState}
        onPressOptionsMenuButton={onPressOptionsMenuButton}
        onEndReached={onEndReached}
        onPullToRefresh={onPullToRefresh}
        onScroll={props.onScroll}
        onEndDrag={props.onEndDrag}
        onMomentumEnd={props.onMomentumEnd}
        headerComponent={headerComponent}
        paddingTop={"headerHeight"}
        paddingBottom={"bottomTabBarHeight"}
        footerComponent={footerComponent}
        inlinedModules={inlinedModules}
        paddingTopScrolled={props.headerHeightScrolled}
      />
    );
  })
);
