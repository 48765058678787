import { DeglazeUser } from "@eatbetter/users-shared";
import { AppDispatch, ThunkAction } from "../redux/Redux";
import { isUserSocialPost, SocialPostId } from "@eatbetter/posts-shared";
import { RootState } from "../redux/RootReducer";
import { bottomWithDefault, dedup, defaultTimeProvider, UserId } from "@eatbetter/common-shared";
import {
  entitiesErrored,
  entitiesReceived,
  entitiesRequested,
  resetState,
  searchCompleted,
  searchErrored,
  searchStarted,
  setCurrentQuery,
} from "./UserSearchSlice";
import { log } from "../../Log";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { debounce } from "lodash";
import { reportUsersSearched } from "../analytics/AnalyticsEvents";
import { analyticsEvent } from "../analytics/AnalyticsThunks";

export type SearchUsersContext =
  | { type: "postComment"; postId: SocialPostId }
  | { type: "shareRecipe"; shareRecipeId: UserRecipeId }
  | { type: "searchUsers" }
  | { type: "newPost" };

/**
 * Should be called at the beginning of an user search screen with search = "" and then for each
 * keystroke. Context is used to seed the initial list of users.
 * @param search
 * @param context
 */
export const searchUsers = (search: string, context: SearchUsersContext): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    const normalized = search.toLowerCase().trim();
    const rootState = getState();
    if (normalized === "") {
      let users: DeglazeUser[];
      switch (context.type) {
        case "postComment":
          users = getUsersForPostComment(rootState, context.postId);
          break;
        case "shareRecipe":
          users = getUsersForShareRecipe(rootState);
          break;
        case "searchUsers":
        case "newPost":
          users = getUsersForUserSearch(rootState);
          break;
        default:
          users = bottomWithDefault(context, [], "UsersThunks.searchUsers");
      }

      const deduped = dedup(users, item => item.userId);
      dispatch(resetState({ initialUsers: deduped }));
      return;
    }

    const state = rootState.users.search;
    dispatch(setCurrentQuery({ query: normalized }));
    debouncedSearchUsersAnalyticsEvent(search, context, dispatch);
    if (state.completedSearches.some(s => s === normalized) || state.pendingSearches.some(s => s === normalized)) {
      return;
    }

    try {
      dispatch(searchStarted({ query: normalized }));
      const results = await deps.api.withThrow().searchUsers({ query: normalized });
      dispatch(searchCompleted({ query: normalized, results: results.data.users }));
    } catch (err) {
      log.errorCaught(`Caught error calling searchUsers for query ${normalized} in getUsersForAtMentions thunk`, err);
      dispatch(searchErrored({ query: normalized }));
    }
  };
};

const debouncedSearchUsersAnalyticsEvent = debounce(
  (query: string, context: SearchUsersContext, dispatch: AppDispatch) => {
    const event = reportUsersSearched({ query, context: context.type });
    dispatch(analyticsEvent(event));
  },
  2000
);

export const loadKnownEntities = (): ThunkAction<void> => {
  return async (dispatch, _getState, deps) => {
    try {
      const ts = defaultTimeProvider();
      dispatch(entitiesRequested(ts));
      const resp = await deps.api.withThrow().getAllKnownEntities();
      dispatch(entitiesReceived({ startTime: ts, data: resp.data }));
    } catch (err) {
      log.errorCaught("Caught unexpected error loading known entities", err);
      dispatch(entitiesErrored());
    }
  };
};

function getUsersForPostComment(state: RootState, postId: SocialPostId): DeglazeUser[] {
  return [...getHouseholdUsers(state), ...getUsersFromPost(state, postId)];
}

function getHouseholdUsers(state: RootState): DeglazeUser[] {
  return state.system.authedUser.data?.household ?? [];
}

function getUsersFromPost(state: RootState, postId?: SocialPostId): DeglazeUser[] {
  if (!postId) {
    return [];
  }

  const post = state.social.posts[postId];

  if (!post) {
    return [];
  }

  const commentUsers = post.comments.items.map(c => c.user);
  const postUser = isUserSocialPost(post) ? [post.user] : [];
  return [...postUser, ...commentUsers];
}

function getUsersForShareRecipe(state: RootState): DeglazeUser[] {
  const userId = state.system.authedUser.data?.userId;

  if (!userId) {
    return [];
  }

  const householdUserIds = getHouseholdUsers(state).map(u => u.userId) ?? [];
  const idsToOmit = [userId, ...householdUserIds];

  // try to find users that interact with the current user.
  // Fall back to any users
  // And finally, fall back to nothing
  return getFavoriteUsers(state, idsToOmit, userId) ?? getFavoriteUsers(state, idsToOmit) ?? [];
}

function getUsersForUserSearch(state: RootState): DeglazeUser[] {
  const userId = state.system.authedUser.data?.userId;
  const idsToOmit = userId ? [userId] : [];
  const favorites = getFavoriteUsers(state, idsToOmit, userId) ?? [];
  return [...favorites, ...getHouseholdUsers(state)];
}

function getFavoriteUsers(state: RootState, idsToOmit: UserId[], userId?: UserId): DeglazeUser[] | undefined {
  const counts: Record<UserId, number> = {};
  const users: Record<UserId, DeglazeUser> = {};

  const increment = (userId: UserId) => {
    if (counts[userId] === undefined) {
      counts[userId] = 0;
    }

    counts[userId] = counts[userId]! + 1;
  };

  const allPosts = Object.values(state.social.posts);

  // try to find users the current user tends to interact with
  // we increment a user for each interaction they have in a post the current
  // user participated in.
  // If no user ID is passed, just find the most active users
  allPosts.forEach(post => {
    if (!userId || isUserInvolved(state, userId, post.id)) {
      getUsersFromPost(state, post.id).forEach(u => {
        increment(u.userId);
        users[u.userId] = u;
      });
    }
  });

  const userIds = Object.entries(counts)
    .sort((a, b) => {
      return b[1] - a[1];
    })
    .map(e => e[0] as UserId)
    .filter(uid => !idsToOmit.includes(uid));

  const results = userIds.map(uid => users[uid]!);

  return results.length > 0 ? results : undefined;
}

function isUserInvolved(state: RootState, userId: UserId, postId: SocialPostId): boolean {
  return getUsersFromPost(state, postId).some(u => u.userId === userId);
}
