import React, { useEffect, useRef } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { RecipeDetailScreenProps } from "../navigation/NavTree";
import { UserRecipeId, haveRecipeIngredients } from "@eatbetter/recipes-shared";
import { globalStyleConstants } from "../components/GlobalStyles";
import { useDispatch } from "../lib/redux/Redux";
import { reportLibraryRecipeDetailView, reportRecipeViewedTime } from "../lib/recipes/RecipesThunks";
import { log } from "../Log";
import { useRecipe } from "../lib/recipes/RecipesSelectors";
import { useCheckpointCompleted } from "../lib/system/SystemSelectors";
import { bottomActionBarConstants } from "../components/BottomActionBar";
import { useResponsiveDimensions } from "../components/Responsive";
import { defaultTimeProvider, EpochMs, daysBetween } from "@eatbetter/common-shared";
import { checkpointsCompleted } from "../lib/system/SystemSlice";
import { spotlight } from "../components/Spotlight";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { View } from "react-native";
import { useExplainerSheet } from "../components/ExplainerSheet";
import { TBody, THeading1 } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { RecipeDetailScreenComponent } from "../components/recipes/RecipeDetailScreenComponent";

const strings = {
  title: "Recipe",
  explainerSheet: {
    headline: "We're working on this one",
    subhead:
      "Most recipes you add will be processed in a few seconds.\n\nIf we're stumped (like in this case), processing can take ~30 min.\n\nPlease check back soon!",
    cta: "Got it",
  },
};

const config = {
  onboardingHint: {
    delayMs: 1000,
    durationMs: 1600,
    buttonWobbleDelayMs: 2800,
  },
  recipeProcessingTimeoutHours: 120, // 5 days
};

// keep track of the last spotlight and only do it once per day (or app lifecycle)
let actionsSpotlighted = 0 as EpochMs;

export const RecipeDetailScreen = withScreenContainer(
  "RecipeDetailScreen",
  (props: RecipeDetailScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const { width: screenWidth, height: screenHeight } = useResponsiveDimensions();
    const { bottomTabBarHeight } = useBottomTabBarDimensions();

    // Onboarding checkpoints
    const libraryRecipeViewedCheckpoint = useCheckpointCompleted("recipeViewedInLibrary");
    const recipeAddedToGroceryCheckpoint = useCheckpointCompleted("recipeAddedToGrocery");
    const cookingSessionStartedCheckpoint = useCheckpointCompleted("cookingSessionStarted");
    const readerModeToggledCheckpoint = useCheckpointCompleted("recipeReaderModeToggled");
    const recipeActionTaken = recipeAddedToGroceryCheckpoint || cookingSessionStartedCheckpoint;

    const recipe = useRecipe(props.recipeId);
    const haveIngredients = !!recipe && haveRecipeIngredients(recipe.ingredients);

    // Onboarding: first recipe viewed
    useEffect(() => {
      if (!libraryRecipeViewedCheckpoint) {
        dispatch(checkpointsCompleted(["recipeViewedInLibrary"]));
      }
    }, [libraryRecipeViewedCheckpoint]);

    // Onboarding: spotlight primary actions if user hasn't taken any yet; comes after reader mode checkpoint
    useEffect(() => {
      const daysSinceLast = daysBetween(actionsSpotlighted, defaultTimeProvider());
      if (
        !readerModeToggledCheckpoint ||
        !screen.nav.focused ||
        !haveIngredients ||
        recipeActionTaken ||
        daysSinceLast < 1
      ) {
        return;
      }

      const spotlightPaddingX = -4;
      const spotlightPaddingY = 0;

      spotlight({
        delayMs: config.onboardingHint.delayMs,
        durationMs: config.onboardingHint.durationMs,
        screenX: 0 - spotlightPaddingX,
        screenY: screenHeight - bottomTabBarHeight - bottomActionBarConstants.height - spotlightPaddingY,
        width: screenWidth + 2 * spotlightPaddingX,
        height: bottomActionBarConstants.height + 2 * spotlightPaddingY,
        analyticsEvent: "spotlightLibraryRecipeActions",
      });

      actionsSpotlighted = defaultTimeProvider();
    }, [readerModeToggledCheckpoint, screen.nav.focused]);

    // Analytics: report recipe viewed
    useEffect(() => {
      dispatch(reportRecipeViewedTime(props.recipeId));

      if (recipe) {
        dispatch(reportLibraryRecipeDetailView(recipe));
      } else {
        log.error(`Recipe detail screen mounted but we have no recipe for id ${props.recipeId}`);
      }
    }, []);

    const initialArchived = useRef(recipe?.archived).current;

    // Go back if there's no recipe
    // we allow archived recipes to be viewed in case they are accessed from the grocery list
    // after the user archives them (i.e. they added it to the list before archiving), but only
    // if they were archived at load. Otherwise, the user might have just archived the recipe.
    useEffect(() => {
      if (!recipe || recipe.deleted || (!initialArchived && recipe.archived)) {
        screen.nav.goBack();
      }
    }, [recipe]);

    // Explainer sheet for the first time a user sees a recipe that failed parsing
    useExplainerSheet({
      checkpoint: "recipeViewedWithParsingFailure",
      closeButtonText: strings.explainerSheet.cta,
      content: <RecipeParseFailedExplainerSheetContent />,
      height: 380,
      condition: screen.nav.focused && recipe?.status === "pendingManual" && !haveIngredients,
      disableGestureDismissal: true,
    });

    return <RecipeDetailScreenComponent recipe={recipe} context="library" />;
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);

const RecipeParseFailedExplainerSheetContent = React.memo(() => {
  return (
    <View style={{ flex: 1, padding: 20 }}>
      <View style={{ height: 40, marginTop: globalStyleConstants.unitSize }}>
        <THeading1 align="center" numberOfLines={1} adjustsFontSizeToFit>
          {strings.explainerSheet.headline}
        </THeading1>
      </View>
      <Spacer vertical={2} />
      <View>
        <TBody align="center">{strings.explainerSheet.subhead}</TBody>
      </View>
    </View>
  );
});
