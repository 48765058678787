import React, { useCallback, useState } from "react";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { Pressable } from "./Pressable";
import { TBody } from "./Typography";
import { Spacer } from "./Spacer";
import { StyleSheet } from "react-native";
import { Haptics } from "./Haptics";

export function useSurveyOptions<TKey extends string>(surveyOptions: Array<{ key: TKey; displayText: string }>) {
  const [selectedOption, setSelectedOption] = useState<{ index: number; key: TKey; displayText: string }>();

  const onPressOption = useCallback(
    (index: number, key: string, displayText: string) => {
      if (index === selectedOption?.index) {
        return;
      }

      setSelectedOption({ index, key: key as TKey, displayText });
      Haptics.feedback("itemStatusChanged");
    },
    [selectedOption, setSelectedOption]
  );

  const renderSurveyOptions = useCallback(() => {
    return (
      <SurveyOptions options={surveyOptions} onPressOption={onPressOption} selectedIndex={selectedOption?.index} />
    );
  }, [surveyOptions, onPressOption, selectedOption]);

  return {
    selectedOption,
    renderSurveyOptions,
  };
}

const SurveyOptions = React.memo(
  (props: {
    options: Array<{ key: string; displayText: string }>;
    selectedIndex?: number;
    onPressOption: (index: number, key: string, displayText: string) => void;
  }) => {
    return (
      <>
        {props.options.map((i, idx) => {
          return (
            <SelectableOption
              key={i.key}
              index={idx}
              isSelected={idx === props.selectedIndex}
              optionKey={i.key}
              displayText={i.displayText}
              onPress={props.onPressOption}
            />
          );
        })}
      </>
    );
  }
);

const SelectableOption = React.memo(
  (props: {
    onPress: (index: number, key: string, displayText: string) => void;
    optionKey: string;
    displayText: string;
    index: number;
    isSelected: boolean;
  }) => {
    const fontWeight = props.isSelected ? "medium" : "normal";
    const borderColor = props.isSelected ? { borderColor: globalStyleColors.colorAccentCool } : {};
    const backgroundColor = props.isSelected
      ? { backgroundColor: globalStyleColors.rgba("colorAccentCool", "xlight") }
      : {};

    const onPress = useCallback(() => {
      props.onPress(props.index, props.optionKey, props.displayText);
    }, [props.index, props.optionKey, props.displayText, props.onPress]);

    return (
      <>
        {props.index !== 0 && <Spacer vertical={1} />}
        <Pressable onPress={onPress} style={[styles.optionButton, borderColor, backgroundColor]} noFeedback>
          <TBody fontWeight={fontWeight} align="center" numberOfLines={1} adjustsFontSizeToFit>
            {props.displayText}
          </TBody>
        </Pressable>
      </>
    );
  }
);

const styles = StyleSheet.create({
  optionButton: {
    width: "100%",
    alignItems: "center",
    borderRadius: 24,
    borderWidth: 1,
    borderColor: globalStyleColors.rgba("colorAccentCool", "medium"),
    padding: globalStyleConstants.unitSize,
    overflow: "hidden",
  },
});
