import React, { useCallback, useMemo } from "react";
import { FlatList, FlatListProps, ListRenderItem, StyleSheet, View } from "react-native";
import { AvatarAndHeading } from "./AvatarAndHeading";
import { globalStyleColors, globalStyleConstants, globalStyles } from "./GlobalStyles";
import { Pressable } from "./Pressable";
import { SelectedCircle } from "./SelectedCircle";
import { Spacer } from "./Spacer";
import { TBody } from "./Typography";
import { FollowEntityButton, IconButton } from "./Buttons";
import { filterOutFalsy, switchReturn } from "@eatbetter/common-shared";
import { AnalyticsFollowUnfollowContext } from "../lib/analytics/AnalyticsEvents";
import { getEntityDisplayText, getSocialEntityId, SocialEntity } from "@eatbetter/posts-shared";
import { SectionHeading } from "./SectionHeading";

export const selectEntityConstants = {
  listItemHeight: 48,
  listItemHorizontalWidth: 164,
  listItemHorizontalHeight: 216,
  listItemSpacerHeight: globalStyleConstants.unitSize,
  listItemSpacerHeightHorizontal: globalStyleConstants.minPadding,
};

const strings = {
  waitingSearch: (query: string) => `Searching for "${query}"`,
  noUsersInitialState: "Start typing to search.",
  noUsersFound: "No users found.",
};

interface SelectEntityBase {
  entity: SocialEntity;
}

export interface SingleSelectEntity extends SelectEntityBase {
  type: "singleSelect";
}

export interface MultiSelectEntity extends SelectEntityBase {
  type: "multiSelect";
  isSelected: boolean;
}

export interface SelectFollowableEntity extends SelectEntityBase {
  type: "followable";
  context: AnalyticsFollowUnfollowContext;
  dismissable?: boolean;
}

export type SelectEntityType = SingleSelectEntity | MultiSelectEntity | SelectFollowableEntity;

export type EntitySelectedHandler = (item: SelectEntityType) => void;

export interface SelectEntityData {
  sectionTitle?: string;
  entities: SelectEntityType[];
}

interface SectionHeader {
  type: "sectionHeader";
  text: string;
}

interface SelectEntityProps
  extends Pick<
    FlatListProps<any>,
    "keyboardDismissMode" | "onScroll" | "onEndReached" | "refreshControl" | "horizontal"
  > {
  type: "overlay" | "flexed" | "inline";
  entityData: SelectEntityData[];
  onSelectEntity: EntitySelectedHandler;
  onDismissEntity?: EntitySelectedHandler;
  queryString?: string;
  waitingForResults: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  paddingHorizontal?: "none" | "default";
  backgroundColor?: "white" | "transparent";
  emptyInitialStateMessage?: string;
  maxCount?: number;
  renderNoResults?: () => React.ReactElement;
  hideKnownEntityPrefix?: boolean;
}

export const SelectEntity = React.memo((props: SelectEntityProps) => {
  const data = useMemo<Array<SectionHeader | SelectEntityType>>(() => {
    return props.entityData.flatMap(i =>
      filterOutFalsy([
        i.sectionTitle ? { type: "sectionHeader", text: i.sectionTitle } : undefined,
        ...i.entities.slice(0, props.entityData.length === 1 ? props.maxCount : undefined),
      ])
    );
  }, [props.entityData, props.maxCount]);

  const onPressListItem = useCallback<EntitySelectedHandler>(
    user => {
      props.onSelectEntity(user);
    },
    [props.onSelectEntity]
  );

  const renderItem: ListRenderItem<SectionHeader | SelectEntityType> = useCallback(
    ({ item }) => {
      switch (item.type) {
        case "sectionHeader": {
          return <SectionHeading text={item.text} noPadding />;
        }
        default: {
          return (
            <EntityListItem
              horizontal={!!props.horizontal}
              item={item}
              onPressItem={onPressListItem}
              onPressDismissItem={props.onDismissEntity}
              hideKnownEntityPrefix={props.hideKnownEntityPrefix}
            />
          );
        }
      }
    },
    [onPressListItem, props.onDismissEntity, props.horizontal, props.hideKnownEntityPrefix]
  );

  const renderItemSeparator = useCallback(() => {
    if (props.horizontal) {
      return <Spacer horizontal={selectEntityConstants.listItemSpacerHeightHorizontal} unit="pixels" />;
    }
    return <Spacer vertical={selectEntityConstants.listItemSpacerHeight} unit="pixels" />;
  }, [props.horizontal]);

  const renderListHeader = useCallback(() => {
    if (props.horizontal) {
      return <Spacer horizontal={selectEntityConstants.listItemSpacerHeightHorizontal} unit="pixels" />;
    }
    return <></>;
  }, [props.horizontal]);

  const renderListFooter = useCallback(() => {
    if (props.horizontal) {
      return <Spacer horizontal={selectEntityConstants.listItemSpacerHeightHorizontal} unit="pixels" />;
    }
    return <></>;
  }, [props.horizontal]);

  const keyExtractor = useCallback((item: SectionHeader | SelectEntityType) => {
    switch (item.type) {
      case "sectionHeader": {
        return item.text;
      }
      default: {
        return getSocialEntityId(item.entity);
      }
    }
  }, []);

  const emptyList = useMemo(
    () => (
      <EmptyList
        waiting={props.waitingForResults}
        queryString={props.queryString}
        emptyInitialStateMessage={props.emptyInitialStateMessage}
        renderNoResults={props.renderNoResults}
      />
    ),
    [props.waitingForResults, props.queryString, props.emptyInitialStateMessage, props.renderNoResults]
  );

  const paddingHorizontal = switchReturn(props.paddingHorizontal ?? "default", {
    none: { paddingHorizontal: 0 },
    default: {},
  });

  const paddingTop = props.paddingTop ? { paddingTop: props.paddingTop } : {};
  const paddingBottom = props.paddingBottom ? { paddingBottom: props.paddingBottom } : {};

  return (
    <FlatList
      horizontal={props.horizontal}
      showsHorizontalScrollIndicator={false}
      data={data}
      renderItem={renderItem}
      ListHeaderComponent={renderListHeader}
      ListFooterComponent={renderListFooter}
      ItemSeparatorComponent={renderItemSeparator}
      keyExtractor={keyExtractor}
      ListEmptyComponent={emptyList}
      style={[
        props.type === "overlay" ? styles.overlay : {},
        styles.backgroundColor,
        props.backgroundColor ? { backgroundColor: globalStyleColors[props.backgroundColor] } : {},
        { overflow: props.horizontal ? "visible" : undefined },
      ]}
      keyboardShouldPersistTaps={props.type === "overlay" ? "always" : "handled"}
      keyboardDismissMode={props.keyboardDismissMode ?? props.type === "inline" ? "none" : undefined}
      contentContainerStyle={[styles.contentContainer, paddingHorizontal, paddingBottom, paddingTop]}
      onScroll={props.onScroll}
      scrollEventThrottle={16}
      onEndReached={props.onEndReached}
      refreshControl={props.refreshControl}
    />
  );
});

export interface EntityListItemProps {
  item: SelectEntityType;
  onPressItem: EntitySelectedHandler;
  onPressDismissItem?: EntitySelectedHandler;
  horizontal?: boolean;
  hideKnownEntityPrefix?: boolean;
}

export const EntityListItem = React.memo((props: EntityListItemProps) => {
  const onPress = useCallback(() => {
    props.onPressItem(props.item);
  }, [props.item, props.onPressItem]);

  const onDismissUser = useCallback(() => {
    props.onPressDismissItem?.(props.item);
  }, [props.onPressDismissItem, props.item]);

  const entityDisplayText = getEntityDisplayText(props.item.entity, {
    numLines: props.horizontal ? "twoLine" : "singleLine",
    prefix: props.hideKnownEntityPrefix ? "none" : undefined,
  });

  return (
    <Pressable style={props.horizontal ? styles.itemHorizontal : styles.item} noFeedback onPress={onPress}>
      <AvatarAndHeading
        horizontal={props.horizontal}
        avatar={{ photo: props.item.entity.photo }}
        heading={{
          text: entityDisplayText.headline,
          size: "secondary",
        }}
        subHeading={{ text: entityDisplayText.subHeadline, opacity: "medium", size: "secondary" }}
      />
      {!!props.horizontal && (
        <>
          <Spacer vertical={1} />
          {props.item.type === "followable" && (
            <>
              <View style={{ width: "100%" }}>
                <FollowEntityButton
                  context={props.item.context}
                  entityId={getSocialEntityId(props.item.entity)}
                  width="flexed"
                />
              </View>
              {!!props.item.dismissable && (
                <View
                  style={{
                    position: "absolute",
                    right: globalStyleConstants.unitSize,
                    top: globalStyleConstants.unitSize,
                  }}
                >
                  <IconButton
                    type="close"
                    onPress={onDismissUser}
                    size="small"
                    opacity="opaque"
                    hitSlop={{
                      top: globalStyleConstants.unitSize,
                      right: globalStyleConstants.unitSize,
                      bottom: globalStyleConstants.unitSize,
                    }}
                  />
                </View>
              )}
            </>
          )}
        </>
      )}
      {!props.horizontal && (
        <>
          <Spacer horizontal={1} />
          {props.item.type === "multiSelect" && <SelectedCircle isSelected={props.item.isSelected} />}
          {props.item.type === "followable" && (
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <FollowEntityButton
                context={props.item.context}
                entityId={getSocialEntityId(props.item.entity)}
                width="contained"
              />
              {!!props.item.dismissable && (
                <>
                  <Spacer horizontal={1.5} />
                  <IconButton type="close" onPress={onDismissUser} />
                </>
              )}
            </View>
          )}
        </>
      )}
    </Pressable>
  );
});

const EmptyList = React.memo(
  (props: {
    waiting: boolean;
    queryString?: string;
    emptyInitialStateMessage?: string;
    renderNoResults?: () => React.ReactElement;
  }) => {
    return (
      <>
        {props.waiting && <AvatarAndHeading waiting={{ message: strings.waitingSearch(props.queryString ?? "") }} />}
        {!props.waiting && (
          <>
            {!!props.queryString && (
              <>
                {!!props.renderNoResults && props.renderNoResults()}
                {!props.renderNoResults && <TBody opacity="medium">{strings.noUsersFound}</TBody>}
              </>
            )}
            {!props.queryString && (
              <TBody opacity="medium">{props.emptyInitialStateMessage ?? strings.noUsersInitialState}</TBody>
            )}
          </>
        )}
      </>
    );
  }
);

export function getSelectableEntities(entities: SocialEntity[], selectedEntities: MultiSelectEntity[]) {
  return entities.map<MultiSelectEntity>(i => ({
    entity: i,
    type: "multiSelect",
    isSelected: !!selectedEntities.find(j => getSocialEntityId(i) === getSocialEntityId(j.entity))?.isSelected,
  }));
}

export function updateSelectableEntities(entities: MultiSelectEntity[], selectedEntities: MultiSelectEntity[]) {
  return entities.map<MultiSelectEntity>(i => ({
    entity: i.entity,
    type: "multiSelect",
    isSelected: !!selectedEntities.find(j => getSocialEntityId(i.entity) === getSocialEntityId(j.entity))?.isSelected,
  }));
}

export function getFollowableEntities(
  entities: SocialEntity[],
  context: AnalyticsFollowUnfollowContext,
  dismissable?: boolean
) {
  return entities.map<SelectFollowableEntity>(i => ({
    entity: i,
    type: "followable",
    context,
    dismissable,
  }));
}

const styles = StyleSheet.create({
  backgroundColor: {
    backgroundColor: "white",
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
  },
  contentContainer: {
    paddingHorizontal: globalStyleConstants.defaultPadding,
    paddingTop: globalStyleConstants.unitSize,
    paddingBottom: globalStyleConstants.unitSize,
  },
  item: {
    height: selectEntityConstants.listItemHeight,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemHorizontal: {
    minHeight: selectEntityConstants.listItemHorizontalHeight,
    width: selectEntityConstants.listItemHorizontalWidth,
    justifyContent: "space-between",
    alignItems: "center",
    padding: globalStyleConstants.unitSize,
    borderRadius: 20,
    backgroundColor: "white",
    ...globalStyles.shadowItem,
    shadowOffset: { ...globalStyles.shadowItem.shadowOffset, width: 12 },
  },
});
