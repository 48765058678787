import React from "react";
import { View, StyleSheet } from "react-native";
import { useBottomTabBarDimensions } from "../navigation/TabBar";

export const BottomNotch = React.memo((props: { backgroundColor?: string }) => {
  const { bottomNotchHeight } = useBottomTabBarDimensions();

  return (
    <>
      {bottomNotchHeight > 0 && (
        <View
          style={[
            styles.bottomNotch,
            props.backgroundColor ? { backgroundColor: props.backgroundColor } : {},
            { height: bottomNotchHeight },
          ]}
        />
      )}
    </>
  );
});

const styles = StyleSheet.create({
  bottomNotch: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    zIndex: 999,
  },
});
