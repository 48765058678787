import { Base64Data, UrlNoProtocolString, UrlString } from "../CommonTypes";
import { emptyToUndefined } from "./Strings";
import { TypedPrimitive } from "../UtilityTypes";

const dataPrefix = "data:";
const base64DataSuffix = ";base64";
export type Base64DataUrl = `${typeof dataPrefix}${string};base64,${string}`;

export function isBase64DataUrl(url: string): url is Base64DataUrl {
  return !!tryParseBase64DataUrl(url);
}

export function isDataUrl(url: string): boolean {
  return url.startsWith(dataPrefix);
}

export function getBase64DatUrl(data: Base64UrlData): Base64DataUrl {
  return `${dataPrefix}${data.contentType ?? ""}${base64DataSuffix},${data.data}` as Base64DataUrl;
}

export interface Base64UrlData {
  data: Base64Data;
  contentType?: string;
}
export function tryParseBase64DataUrl(url: string): Base64UrlData | undefined {
  if (!url.startsWith(dataPrefix)) {
    return undefined;
  }

  const parts = url.substring(dataPrefix.length).split(",");
  if (parts.length !== 2) {
    return undefined;
  }

  // data:image/jpeg;base64,ABCD - valid - most common for inlined images
  // data:;base64,UEsDBBQAAAAI - valid, no content-type
  // data:image/jpeg;key=value;base64,UEsDBBQAAAAI - valid, multiple semicolons

  const data = parts[1] as Base64Data;
  const meta = parts[0]!;

  // data: urls are not necessarily base64 encoded. That's all we are expecting to deal with, though.
  if (!meta.endsWith(base64DataSuffix)) {
    return undefined;
  }

  const lastIndex = meta.lastIndexOf(";");
  const contentType = emptyToUndefined(meta.substring(0, lastIndex));

  return { data, contentType };
}

export function isUrl(
  url: string,
  opts: { allowDataProtocol: boolean; addProtocolIfMissing: boolean } = {
    allowDataProtocol: false,
    addProtocolIfMissing: true,
  }
): url is UrlString {
  try {
    const withProtocol = opts.addProtocolIfMissing ? addProtocolIfMissing(url as UrlString) : (url as UrlString);
    const u = new URL(withProtocol);
    return u.protocol.startsWith("http") || (opts.allowDataProtocol && u.protocol === "data:");
  } catch {
    return false;
  }
}

export type UrlHost = TypedPrimitive<string, "urlHost">;
export function getUrlHost(url: UrlString, opts?: { removeWww?: boolean }): UrlHost {
  const host = new URL(url).host as UrlHost;
  if (opts?.removeWww) {
    // adding www2 specifically for mob, but I'm wondering if we should remove any www*.
    // leaving as is for now as I'm not sure if there are any cases where we would want to treat that as a separate domain - probably not
    const prefixes = ["www.", "www2."];
    for (const prefix of prefixes) {
      if (host.startsWith(prefix)) {
        return host.substring(prefix.length) as UrlHost;
      }
    }

    return host;
  }

  return host;
}

/**
 * Returns true if the URL host = domain or ends with `.${domain}
 * @param domain
 * @param url
 */
export function is2ndLevelDomainMatch(domain: string, url: UrlString): boolean {
  const host = getUrlHost(url);
  const lcDomain = domain.toLowerCase();
  return host === lcDomain || host.endsWith(`.${lcDomain}`);
}

export function getUrlHostDisplayName(url: UrlString): string {
  return getUrlHost(url, { removeWww: true });
}

export function normalizeUrl(url: UrlString): UrlString {
  const withProtocol = addProtocolIfMissing(url);

  // this will normalize the URL. Namely, lowercase the host.
  return new URL(withProtocol).toString() as UrlString;
}

function addProtocolIfMissing(url: UrlString): UrlString {
  return url.startsWith("//") ? (`https:${url}` as UrlString) : url;
}

export function normalizeUrlAndRemoveProtocol(url: UrlString): UrlNoProtocolString {
  const u = new URL(url);
  return u.toString().substring(u.protocol.length) as UrlNoProtocolString;
}

export function urlHasRootPath(url: UrlString): boolean {
  const u = new URL(url);
  return u.pathname === "/";
}

export function getPathAndQuery(url: UrlString): string {
  const u = new URL(url);
  return u.pathname + u.search;
}

export function getDisplayUrl(url: UrlString): string {
  const urlNoProtocol = normalizeUrlAndRemoveProtocol(url);
  const slashesPrefix = "//";
  const wwwPrefix = "www.";
  const urlNoProtocolNoSlashes = (
    urlNoProtocol.startsWith(slashesPrefix) ? urlNoProtocol.substring(slashesPrefix.length) : urlNoProtocol
  ).replace(/\/+$/, "");
  return urlNoProtocolNoSlashes.startsWith(wwwPrefix)
    ? urlNoProtocolNoSlashes.substring(wwwPrefix.length)
    : urlNoProtocolNoSlashes;
}

export function getInstagramProfileUrl(handle: string): UrlString {
  return `https://www.instagram.com/${handle}/` as UrlString;
}
