import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { BrowseKnownEntitiesScreenProps } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { HeaderProps } from "../components/ScreenHeaders";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox";
import { useDispatch } from "../lib/redux/Redux";
import { useKeyboardLayoutAnimation } from "../components/Keyboard";
import { useKnownEntities } from "../lib/users/UsersSelectors";
import { loadKnownEntities } from "../lib/users/UsersThunks";
import { log } from "../Log";
import { LayoutAnimation } from "react-native";
import {
  EntitySelectedHandler,
  SelectEntity,
  SelectEntityData,
  getFollowableEntities,
} from "../components/SelectEntity";
import { navToEntityScreen } from "../lib/social/SocialThunks";
import { getSocialEntityId } from "@eatbetter/posts-shared";
import { globalStyleConstants } from "../components/GlobalStyles";
import { SearchKnownEntitiesNoResults } from "../components/SearchEntities";
import {
  BrowseAllEntitiesViewedFrom,
  reportBrowseAllEntitiesViewed,
  reportKnownEntitiesSearched,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { debounce } from "lodash";

const strings = {
  screenTitle: "Recipe Collections",
  searchPlaceholder: "Search recipe collections",
};

export const BrowseKnownEntitiesScreen = withScreenContainer(
  "BrowseKnownEntitiesScreen",
  (props: BrowseKnownEntitiesScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const { headerHeight, bottomTabBarHeight } = useScreenElementDimensions();
    const searchBarHeight = useSearchAndFilterBarHeight();
    const keyboardHeight = useKeyboardLayoutAnimation();

    const [searchPhrase, setSearchPhrase] = useState(props.searchQuery ?? "");

    const knownEntityResults = useKnownEntities(searchPhrase);

    const alreadyReportedView = useRef(false);

    useEffect(() => {
      if (!alreadyReportedView.current && screen.nav.focused) {
        const event = reportBrowseAllEntitiesViewed({
          from: props.analyticsContext,
          initialSearchQuery: props.searchQuery,
        });
        dispatch(analyticsEvent(event));
        alreadyReportedView.current = true;
      }
    }, [screen.nav.focused]);

    useEffect(() => {
      dispatch(loadKnownEntities()).catch(err =>
        log.errorCaught("Error calling loadKnownEntities on SearchUsersScreen mount", err)
      );
    }, []);

    const debouncedSearchUsersAnalyticsEvent = useCallback(
      debounce((searchQuery: string) => {
        if (searchQuery) {
          const event = reportKnownEntitiesSearched({ searchQuery });
          dispatch(analyticsEvent(event));
        }
      }, 2000),
      [dispatch]
    );

    const onChangeSearchPhrase = useCallback(
      (text: string) => {
        if (screen.nav.focused) {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }
        debouncedSearchUsersAnalyticsEvent(text);
        setSearchPhrase(text);
      },
      [screen.nav.focused, setSearchPhrase, debouncedSearchUsersAnalyticsEvent]
    );

    const onSelectEntity = useCallback<EntitySelectedHandler>(
      item => {
        dispatch(navToEntityScreen(getSocialEntityId(item.entity), screen.nav, "browseAllEntities"));
      },
      [dispatch, screen.nav]
    );

    const entityData = useMemo<SelectEntityData[]>(() => {
      return [
        {
          entities: getFollowableEntities(knownEntityResults.entities, "entitySearch"),
        },
      ];
    }, [knownEntityResults.entities]);

    const renderNoResults = useCallback(() => {
      return <SearchKnownEntitiesNoResults />;
    }, []);

    const screenHeader = useMemo<HeaderProps>(() => {
      return {
        type: "custom",
        title: strings.screenTitle,
        subHeaderComponent: {
          render: () => (
            <SearchAndFilterBar
              searchPhrase={searchPhrase}
              onChangeSearchPhrase={onChangeSearchPhrase}
              placeholderText={strings.searchPlaceholder}
            />
          ),
          getHeight: () => searchBarHeight,
        },
        backgroundColor: "white",
      };
    }, [searchBarHeight, onChangeSearchPhrase, searchPhrase]);

    return (
      <ScreenView
        loading={knownEntityResults.loading}
        header={screenHeader}
        scrollView={false}
        paddingHorizontal={false}
        paddingVertical={false}
      >
        <SelectEntity
          type="flexed"
          entityData={entityData}
          queryString={searchPhrase}
          waitingForResults={knownEntityResults.loading}
          onSelectEntity={onSelectEntity}
          paddingTop={headerHeight + searchBarHeight + 1.5 * globalStyleConstants.unitSize}
          paddingBottom={(keyboardHeight > 0 ? keyboardHeight : bottomTabBarHeight) + 4 * globalStyleConstants.unitSize}
          renderNoResults={renderNoResults}
          hideKnownEntityPrefix
        />
        {!!searchPhrase && knownEntityResults.entities.length === 0 && <SearchKnownEntitiesNoResults />}
      </ScreenView>
    );
  },
  {
    serializer: {
      searchQuery: s => s,
      analyticsContext: s => s,
    },
    parser: {
      searchQuery: s => s,
      analyticsContext: s => s as BrowseAllEntitiesViewedFrom,
    },
  }
);
