import { PhotoRef, PhotoSize } from "./PhotoTypes";
import { UrlString } from "@eatbetter/common-shared";

export function getUrlForImage(env: "prod" | "dev" | "local", ref: PhotoRef, size: PhotoSize): UrlString {
  const photosCdnBaseUrl = env === "prod" ? "https://cdn.deglaze.app/photos" : "https://cdn.mooklab-dev.link/photos";
  if (ref.type === "internal") {
    return `${photosCdnBaseUrl}/${ref.id}/${size}` as UrlString;
  } else if (ref.cdn) {
    return `${photosCdnBaseUrl}/${ref.cdn.id}/${size}?h=${ref.cdn.h}` as UrlString;
  } else {
    return getHttpsUrl(ref.url);
  }
}

/**
 * Converts 'http://' URLs to 'https://' to avoid 307 redirects (which react native image does not support)
 * In theory, all external image URLs should be https anyway, so this should do no harm
 */
function getHttpsUrl(url: string): UrlString {
  if (!url.startsWith("http://")) {
    return url as UrlString;
  }
  return url.replace("http://", "https://") as UrlString;
}
