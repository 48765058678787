import React, { useCallback } from "react";
import { StyleSheet, View } from "react-native";
import { TSecondary } from "../Typography";
import { Spacer } from "../Spacer";
import { ButtonRectangle } from "../Buttons";
import { useDispatch } from "../../lib/redux/Redux";
import { globalStyleConstants } from "../GlobalStyles";
import { Separator } from "../Separator";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { reportFindOrInviteFriendsButtonPressed } from "../../lib/analytics/AnalyticsEvents";
import { switchReturn } from "@eatbetter/common-shared";
import { CtaLocation } from "@eatbetter/composite-shared";

const strings = {
  cta: "Find People + Collections",
  emptyFeed: "Personalize your feed by following friends and recipe collections.",
  inlineFeed: "Follow friends and recipe collections to discover great new recipes from people you trust.",
};

interface InviteFriendsProps {
  context: "emptyFeed" | "inlineFeed";
}

export const InviteFriends = React.memo((props: InviteFriendsProps) => {
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const ctaLocation: CtaLocation = switchReturn(props.context, {
    emptyFeed: "Empty Feed",
    inlineFeed: "Inline Feed",
  });

  const onPress = useCallback(() => {
    dispatch(analyticsEvent(reportFindOrInviteFriendsButtonPressed(ctaLocation)));
    nav.goTo("push", navTree.get.screens.searchEntities);
  }, [dispatch, props.context]);

  const message = strings[props.context];

  return (
    <>
      <View style={props.context === "emptyFeed" ? styles.emptyFeed : styles.inlineFeed}>
        <TSecondary
          fontWeight={props.context === "emptyFeed" ? "normal" : "medium"}
          align={props.context === "emptyFeed" ? "left" : "center"}
        >
          {message}
        </TSecondary>
        <Spacer vertical={1.5} />
        <ButtonRectangle
          type={props.context === "inlineFeed" ? "submit" : "secondary"}
          icon="addFriends"
          size="medium"
          singlePress
          title={strings.cta}
          onPress={onPress}
        />
      </View>
      {props.context === "inlineFeed" && (
        <View style={{ paddingHorizontal: globalStyleConstants.minPadding }}>
          <Spacer vertical={3} />
          <Separator orientation="row" />
        </View>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  emptyFeed: {},
  inlineFeed: {
    paddingHorizontal: globalStyleConstants.defaultPadding,
    paddingTop: 3 * globalStyleConstants.unitSize,
  },
});
