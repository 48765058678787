import React, { useEffect } from "react";
import { Animated, StyleSheet, View } from "react-native";
import { useBottomTabBarDimensions } from "../navigation/TabBar";
import { ButtonRectangle, ButtonRectangleProps } from "./Buttons";
import { globalStyleColors, globalStyleConstants, globalStyles } from "./GlobalStyles";
import { Spacer } from "./Spacer";
import { useWobbleAnimation } from "./AttentionGrabbers";
import { switchReturn } from "@eatbetter/common-shared";

export const bottomActionBarConstants = {
  height: 82,
  defaultBackgroundColor: globalStyleColors.white,
};

export interface BottomActionBarButtonProps {
  onPressAction: () => void;
  actionIcon?: ButtonRectangleProps["icon"];
  actionText: string;
  disabled?: boolean;
  waiting?: boolean;
  singlePress?: boolean;
  showTapHint?: { delay: number };
}

export interface BottomActionBarProps {
  primaryAction: BottomActionBarButtonProps;
  secondaryAction?: BottomActionBarButtonProps;
  disableSnapToBottom?: boolean;
  containerBackgroundColor?: string;
  noBorderRadius?: boolean;
  border?: "none" | "thin" | "thick";
}

export const BottomActionBar = React.memo((props: BottomActionBarProps) => {
  const { bottomTabBarHeight } = useBottomTabBarDimensions();

  const position = props.disableSnapToBottom ? undefined : "absolute";
  const bottom = props.disableSnapToBottom ? undefined : bottomTabBarHeight;
  const backgroundColor = props.containerBackgroundColor ?? bottomActionBarConstants.defaultBackgroundColor;
  const borderRadius = props.noBorderRadius ? undefined : 20;
  const border =
    props.containerBackgroundColor === "transparent"
      ? {}
      : switchReturn(props.border ?? "thin", {
          none: {},
          thin: globalStyles.borderBottomBarThin,
          thick: globalStyles.borderBottomBarThick,
        });

  return (
    <View
      style={[
        styles.container,
        { position, bottom, backgroundColor, borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius },
        border,
      ]}
    >
      {!!props.secondaryAction && (
        <>
          <BottomActionButton {...props.secondaryAction} isSecondary />
          <Spacer horizontal={1} />
        </>
      )}
      <BottomActionButton {...props.primaryAction} />
    </View>
  );
});

const BottomActionButton = React.memo((props: BottomActionBarButtonProps & { isSecondary?: boolean }) => {
  const wobbleAnimation = useWobbleAnimation({ delay: props.showTapHint?.delay });

  useEffect(() => {
    if (!props.showTapHint) {
      return;
    }

    wobbleAnimation.startAnimation();
  }, []);

  return (
    <Animated.View style={[styles.button, wobbleAnimation.animatedStyle]}>
      <ButtonRectangle
        size="large"
        disabled={props.disabled}
        singlePress={props.singlePress ?? true}
        waiting={props.waiting}
        onPress={props.onPressAction}
        icon={props.actionIcon}
        title={props.actionText}
        type={props.isSecondary ? "secondary" : "submit"}
      />
    </Animated.View>
  );
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    left: 0,
    right: 0,
    height: bottomActionBarConstants.height,
    zIndex: 1,
    paddingVertical: globalStyleConstants.unitSize,
    paddingHorizontal: 20,
  },
  button: {
    flex: 1,
    minWidth: 160,
  },
});
