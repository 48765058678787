import React, { useCallback } from "react";
import { TSecondary } from "./Typography";
import { Spacer } from "./Spacer";
import { globalStyleColors } from "./GlobalStyles";
import { switchReturn } from "@eatbetter/common-shared";
import { useDispatch } from "../lib/redux/Redux";
import { useScreen } from "../navigation/ScreenContainer";
import { reportIssue } from "../lib/system/SystemThunks";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { Haptics } from "./Haptics";
import { navTree } from "../navigation/NavTree";
import { InviteFriendsTextButton } from "./InviteFriends";

const strings = {
  noResults: (type: "knownEntities" | "deglazeUsers") => [
    "0",
    ` ${switchReturn(type, { knownEntities: "collection results", deglazeUsers: "user results" })}`,
  ],
  requestCollection: ["Tap here to request ", "an author or publisher you'd like to see here."],
  reportMissingEntityScreenTitle: "Request new author or publisher",
  reportMissingEntityPlaceholderText: "Tell us the name of the author or publisher you'd like to see on Deglaze",
};

export const SearchKnownEntitiesNoResults = React.memo(() => {
  const dispatch = useDispatch();
  const screen = useScreen();

  const onSubmitFeedback = useCallback(
    async (userText: string) => {
      try {
        await dispatch(reportIssue({ type: "requestedEntityPage", name: userText + "\n\n[manually entered by user]" }));
      } catch (err) {
        displayUnexpectedErrorAndLog("Search users screen: error caught in onSubmitFeedback()", err, {
          userText,
        });

        if (userText) {
          // Throw so we can catch and handle appropriately in the "other feedback" form
          throw err;
        }

        // Don't nav back on error
        return;
      }
      Haptics.feedback("operationSucceeded");
      screen.nav.goBack();
    },
    [dispatch, screen.nav.goBack]
  );

  const onPressReportMissingEntity = useCallback(() => {
    screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
      screenTitle: strings.reportMissingEntityScreenTitle,
      headline: "",
      textInputPlaceholder: strings.reportMissingEntityPlaceholderText,
      onSubmit: onSubmitFeedback,
    });
    return;
  }, [screen.nav.modal, onSubmitFeedback]);

  return (
    <>
      <SearchEntitiesNoResults entityType="knownEntities" />
      <TSecondary>
        <TSecondary
          onPress={onPressReportMissingEntity}
          suppressHighlighting
          color={globalStyleColors.colorTextLink}
          fontWeight="medium"
        >
          {strings.requestCollection[0]}
        </TSecondary>
        <TSecondary opacity="medium">{strings.requestCollection[1]}</TSecondary>
      </TSecondary>
    </>
  );
});

export const SearchUsersNoResults = React.memo(() => {
  return (
    <>
      <SearchEntitiesNoResults entityType="deglazeUsers" />
      <InviteFriendsTextButton />
    </>
  );
});

export const SearchEntitiesNoResults = React.memo((props: { entityType: "knownEntities" | "deglazeUsers" }) => {
  const noResults = strings.noResults(props.entityType);

  return (
    <>
      <TSecondary opacity="medium">
        <TSecondary fontWeight="medium">{noResults[0]}</TSecondary>
        <TSecondary>{noResults[1]}</TSecondary>
      </TSecondary>
      <Spacer vertical={1} />
    </>
  );
});
