import { Envelope, LinkInfo, LogLines, MetaHeaders } from "@eatbetter/common-shared";
import {
  AppCreateUserArgs,
  AuthedUserAndSettings,
  GetNotificationsArgs,
  HouseholdInviteInfo,
  HouseholdInviteLink,
  Notifications,
  NullableUserCheckpoints,
  CreatePushTokenArgs,
  SearchUsersArgs,
  SearchUsersResult,
  StoreAppStateArgs,
  UpdateNotificationLastReadArgs,
  UpdateUserProfileArgs,
  UserCheckpoints,
  UserErrorTypes,
  UsernameAvailableRequest,
  UsernameAvailableResult,
  WithVersion,
  SaveAppleNameArgs,
} from "@eatbetter/users-shared";
import {
  AddManualGroceryListItem,
  PersistedVersions,
  GroceryListItem,
  GroceryLists,
  UpdateGroceryListItem,
  ListsErrorTypes,
  DeleteGroceryListRecipe,
  GroceryListSuggestions,
} from "@eatbetter/lists-shared";
import { GroceryListAutocompleteQuery, GroceryListAutocompleteResults } from "@eatbetter/items-shared";
import {
  AddRecipeArgs,
  AddRecipeFromUrlArgs,
  ArchiveRecipeArgs,
  EditRecipeArgs,
  GetRecipeArgs,
  AppUserRecipe,
  RecipeCookedArgs,
  RecipeErrorTypes,
  UserRecipes,
  RecipeViewedArgs,
  GetUserRecipeArgs,
  ShareRecipeArgs,
  SaveSharedRecipeArgs,
  AppRecipeBase,
  EditUserRecipeNoteArgs,
  EditUserRecipeTimeArgs,
  RecipesScript,
  EditUserRecipeRatingArgs,
  KnownEntities,
} from "@eatbetter/recipes-shared";
import {
  AddRecipeIngredientsToGroceryListArgs,
  AppMetadata,
  EndCookingSessionArgsV2,
  GetShareDataArgs,
  ReportIssueData,
  ShareData,
} from "@eatbetter/composite-shared";
import {
  CookingSession,
  CookingSessionErrorTypes,
  CreateCookingSessionArgs,
  UpdateIngredientStatusArgs,
  UpdateSelectedInstructionArgs,
} from "@eatbetter/cooking-shared";
import {
  AddCommentArgs,
  CreateTextPostClientArgs,
  DeleteCommentArgs,
  DeletePostArgs,
  GetPostArgs,
  GetFeedPostsArgs,
  LikeUnlikePostArgs,
  SaveRecipeFromPostArgs,
  SaveRecipeFromPostResult,
  SocialErrorTypes,
  SocialPost,
  SocialPosts,
  GetProfilePostsArgs,
  SocialProfilePosts,
  EntityFollowers,
  ReportDismissedFollowRecommendationArgs,
  SocialTestHooksArgs,
  UserFollowingInfoAndRecommendations,
  GetKnownAuthorProfileArgs,
  KnownAuthorProfileInfo,
  GetKnownPublisherProfileArgs,
  KnownPublisherProfileInfo,
  GetFollowingArgs,
  UserFollowing,
  GetFollowersArgs,
  FollowUnfollowEntityArgs,
} from "@eatbetter/posts-shared";
import {
  S3UploadAddPhotoArgs,
  DeletePhotoArgs,
  PhotoErrorTypes,
  UploadPhotoRequestInfo,
} from "@eatbetter/photos-shared";
import {
  ApiClientBase,
  ApiClientConstructorArgs,
  ApiUrls,
  AppEnvelope,
  ErrorStrategy,
  getApiClientFactory,
} from "./ApiClientBase";
import { SetWaitingHandler } from "./Types";
import { GetUserRecipesByLastUpdatedArgs } from "@eatbetter/recipes-shared/dist/RecipeTypes";
import { EditUserRecipeTagArgs } from "@eatbetter/recipes-shared/dist/RecipeTagTypes";
import { CurrentEnvironment } from "../CurrentEnvironment";
import {
  SaveRecipeFromSearchArgs,
  SaveRecipeFromSearchResult,
  SearchArgs,
  SearchResults,
  SearchSuggestionArgs,
  SearchSuggestions,
} from "@eatbetter/search-shared";

export function getAppApiClientFactory(args: {
  urls: ApiUrls;
  metaHeaders: MetaHeaders;
  getToken: () => Promise<string | undefined>;
}) {
  const api = getApiClientFactory<ApiClient<"returnErrorEnvelope">, ApiClient<"throwOnError">>({
    returnFactory: (waitingHandler?: SetWaitingHandler) => {
      return new ApiClient({
        urls: args.urls,
        getToken: args.getToken,
        metaHeaders: args.metaHeaders,
        errorEnvelopeStrategy: "returnErrorEnvelope",
        waitingHandler,
      });
    },
    throwFactory: (waitingHandler?: SetWaitingHandler) => {
      return new ApiClient({
        urls: args.urls,
        getToken: args.getToken,
        metaHeaders: args.metaHeaders,
        errorEnvelopeStrategy: "throwOnError",
        waitingHandler,
      });
    },
  });

  return api;
}

/***
 * The API has 2 error handling modes.
 * 1. throwOnError - in this mode, an error is thrown if an ErrorEnvelope is encountered.
 *    In this mode, the return types are DataEnvelopes, so callers don't need to check for errors.
 * 2. returnErrorEnvelope - in this mode, callers must check for errors before using data (or just
 *    check the existence of data to ensure the call succeeded).
 */
export class ApiClient<TErrorHandling extends ErrorStrategy> extends ApiClientBase<TErrorHandling> {
  constructor(opts: ApiClientConstructorArgs) {
    super(opts);
  }

  async getAppMetadata(): Promise<AppEnvelope<TErrorHandling, AppMetadata>> {
    return this.post<{}, AppMetadata>("gateway", "/u/composite/app-metadata", {});
  }

  async getShareData(args: GetShareDataArgs): Promise<AppEnvelope<TErrorHandling, ShareData>> {
    return this.post<GetShareDataArgs, ShareData>("gateway", "/u/share-data", args);
  }

  async createUser(args: AppCreateUserArgs): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post<AppCreateUserArgs, {}, UserErrorTypes>("gateway", "/user/me", args);
  }

  async deleteAccount(): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post<{}, {}, UserErrorTypes>("gateway", "/user/me/delete", {});
  }

  async updateUserProfile(args: UpdateUserProfileArgs): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post<UpdateUserProfileArgs, {}, UserErrorTypes>("gateway", "/user/update-profile", args);
  }

  async updateUserCheckpoints(
    args: NullableUserCheckpoints
  ): Promise<AppEnvelope<TErrorHandling, WithVersion<UserCheckpoints>, UserErrorTypes>> {
    return this.post<NullableUserCheckpoints, WithVersion<UserCheckpoints>, UserErrorTypes>(
      "gateway",
      "/user/update-checkpoints",
      args
    );
  }

  async isUsernameAvailable(
    args: UsernameAvailableRequest
  ): Promise<AppEnvelope<TErrorHandling, UsernameAvailableResult, UserErrorTypes>> {
    return this.post<UsernameAvailableRequest, UsernameAvailableResult, UserErrorTypes>(
      "gateway",
      "/user/username-available",
      args
    );
  }

  async getAuthedUser(): Promise<AppEnvelope<TErrorHandling, AuthedUserAndSettings, UserErrorTypes>> {
    return this.get<AuthedUserAndSettings, UserErrorTypes>("gateway", "/user/me");
  }

  async saveAppleName(args: SaveAppleNameArgs): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post<SaveAppleNameArgs, {}, UserErrorTypes>("gateway", "/user/save-apple-name", args);
  }

  async searchUsers(search: SearchUsersArgs): Promise<AppEnvelope<TErrorHandling, SearchUsersResult, UserErrorTypes>> {
    return this.post<SearchUsersArgs, SearchUsersResult, UserErrorTypes>("gateway", "/user/search", search);
  }

  async getNotifications(
    args: GetNotificationsArgs
  ): Promise<AppEnvelope<TErrorHandling, Notifications, UserErrorTypes>> {
    return this.post<GetNotificationsArgs, Notifications, UserErrorTypes>(
      "gateway",
      "/user/notifications/get-list",
      args
    );
  }

  async updateNotificationLastReadTime(
    args: UpdateNotificationLastReadArgs
  ): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post<UpdateNotificationLastReadArgs, {}, UserErrorTypes>(
      "gateway",
      "/user/notifications/update-last-read",
      args
    );
  }

  async getHouseholdInviteLink(): Promise<AppEnvelope<TErrorHandling, LinkInfo, UserErrorTypes>> {
    return this.get<LinkInfo, UserErrorTypes>("gateway", "/user/household/link");
  }

  async getHouseholdLinkInfoAuthed(
    req: HouseholdInviteLink
  ): Promise<AppEnvelope<TErrorHandling, HouseholdInviteInfo, UserErrorTypes>> {
    return this.post<HouseholdInviteLink, HouseholdInviteInfo, UserErrorTypes>(
      "gateway",
      "/user/household/link/verify",
      req
    );
  }

  async getHouseholdLinkInfoNoAuth(
    req: HouseholdInviteLink
  ): Promise<AppEnvelope<TErrorHandling, HouseholdInviteInfo, UserErrorTypes>> {
    return this.post<HouseholdInviteLink, HouseholdInviteInfo, UserErrorTypes>(
      "gateway",
      "/u/user/household/link/info",
      req
    );
  }

  async completeHouseholdLink(req: HouseholdInviteLink): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post<HouseholdInviteLink, {}, UserErrorTypes>("gateway", "/user/household/join", req);
  }

  async sendLogs(req: LogLines): Promise<AppEnvelope<TErrorHandling, {}>> {
    return this.post<LogLines, {}>("gateway", "/log", req, { log: false });
  }

  async createPushToken(token: CreatePushTokenArgs): Promise<AppEnvelope<TErrorHandling, {}>> {
    return this.post<{ token: CreatePushTokenArgs }, {}>("gateway", "/user/notifications/create-push-token", { token });
  }

  async websocketPush(message: string): Promise<Envelope<{}>> {
    return this.post<{ message: string }, {}>("gateway", "/user/websocket/push", { message });
  }

  async getGroceryLists(): Promise<AppEnvelope<TErrorHandling, GroceryLists>> {
    return this.get<GroceryLists>("gateway", "/lists");
  }

  async getGroceryListSuggestions(): Promise<AppEnvelope<TErrorHandling, GroceryListSuggestions>> {
    return this.post<{}, GroceryListSuggestions, ListsErrorTypes>("gateway", "/lists/get-suggestions", {});
  }

  async addGroceryListItem(
    req: AddManualGroceryListItem
  ): Promise<AppEnvelope<TErrorHandling, PersistedVersions, ListsErrorTypes>> {
    return this.post<AddManualGroceryListItem, PersistedVersions, ListsErrorTypes>("gateway", "/lists/item", req);
  }

  async addRecipeIngredientsToGroceryList(
    req: AddRecipeIngredientsToGroceryListArgs
  ): Promise<AppEnvelope<TErrorHandling, {}, ListsErrorTypes>> {
    return this.post<AddRecipeIngredientsToGroceryListArgs, {}, ListsErrorTypes>(
      "gateway",
      "/composite/add-recipe-ingredients-to-list",
      req
    );
  }

  async deleteRecipeIngredientsFromGroceryList(
    req: DeleteGroceryListRecipe
  ): Promise<AppEnvelope<TErrorHandling, {}, ListsErrorTypes>> {
    return this.post<DeleteGroceryListRecipe, {}, ListsErrorTypes>("gateway", "/lists/delete-recipe", req);
  }

  async updateGroceryListItem(
    req: UpdateGroceryListItem
  ): Promise<AppEnvelope<TErrorHandling, GroceryListItem, ListsErrorTypes>> {
    return this.patch<UpdateGroceryListItem, GroceryListItem, ListsErrorTypes>("gateway", "/lists/item", req);
  }

  async getGroceryItemSuggestions(
    req: GroceryListAutocompleteQuery
  ): Promise<AppEnvelope<TErrorHandling, GroceryListAutocompleteResults, ListsErrorTypes>> {
    return this.post<GroceryListAutocompleteQuery, GroceryListAutocompleteResults, ListsErrorTypes>(
      "items",
      "/grocery-list/autocomplete",
      req
    );
  }

  async getRecipe(req: GetRecipeArgs): Promise<AppEnvelope<TErrorHandling, AppRecipeBase, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/get", req);
  }

  async getUserRecipe(req: GetUserRecipeArgs): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/get-user-recipe", req);
  }

  async getRecipes(
    req: GetUserRecipesByLastUpdatedArgs
  ): Promise<AppEnvelope<TErrorHandling, UserRecipes, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/get-list", req);
  }

  async addManualRecipe(req: AddRecipeArgs): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/add", req);
  }

  async addRecipeFromUrl(
    req: AddRecipeFromUrlArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/add-from-url", req);
  }

  async editRecipe(req: EditRecipeArgs): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/edit", req);
  }

  async editRecipeNote(
    req: EditUserRecipeNoteArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/edit-note", req);
  }

  async editRecipeTime(
    req: EditUserRecipeTimeArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/edit-time", req);
  }

  async editRecipeRating(
    req: EditUserRecipeRatingArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/edit-rating", req);
  }

  async editUserRecipeTag(
    req: EditUserRecipeTagArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/tag-user-recipe", req);
  }

  async archiveRecipe(req: ArchiveRecipeArgs): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/archive", req);
  }

  async unarchiveRecipe(req: ArchiveRecipeArgs): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/unarchive", req);
  }

  async saveSharedRecipe(
    req: SaveSharedRecipeArgs
  ): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/save-shared", req);
  }

  async shareRecipe(req: ShareRecipeArgs): Promise<AppEnvelope<TErrorHandling, {}, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/share", req);
  }

  async reportRecipeView(req: RecipeViewedArgs): Promise<AppEnvelope<TErrorHandling, AppUserRecipe, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/recipe-viewed", req);
  }

  async reportRecipeCooked(req: RecipeCookedArgs): Promise<AppEnvelope<TErrorHandling, {}, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/recipe-cooked", req);
  }

  async getRecipesScript(): Promise<AppEnvelope<TErrorHandling, RecipesScript, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/script", {});
  }

  async getActiveCookingSessions(): Promise<AppEnvelope<TErrorHandling, CookingSession[], CookingSessionErrorTypes>> {
    return this.post("gateway", "/cooking/sessions/get-active", {});
  }

  async createNewCookingSession(
    req: CreateCookingSessionArgs
  ): Promise<AppEnvelope<TErrorHandling, CookingSession, CookingSessionErrorTypes>> {
    return this.post("gateway", "/cooking/sessions/create", req);
  }

  async endCookingSession(
    req: EndCookingSessionArgsV2
  ): Promise<AppEnvelope<TErrorHandling, {}, CookingSessionErrorTypes>> {
    return this.post("gateway", "/cooking/sessions/end-session", req);
  }

  async updateCookingSessionIngredientStatus(
    req: UpdateIngredientStatusArgs
  ): Promise<AppEnvelope<TErrorHandling, CookingSession, CookingSessionErrorTypes>> {
    return this.post("gateway", "/cooking/sessions/update-ingredient-status", req);
  }

  async updateCookingSessionSelectedInstruction(
    req: UpdateSelectedInstructionArgs
  ): Promise<AppEnvelope<TErrorHandling, CookingSession, CookingSessionErrorTypes>> {
    return this.post("gateway", "/cooking/sessions/update-selected-instruction", req);
  }

  async getPost(req: GetPostArgs): Promise<AppEnvelope<TErrorHandling, SocialPost, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/get", req);
  }

  async getPosts(req: GetFeedPostsArgs): Promise<AppEnvelope<TErrorHandling, SocialPosts, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/get-list", req);
  }

  async getProfilePosts(
    req: GetProfilePostsArgs
  ): Promise<AppEnvelope<TErrorHandling, SocialProfilePosts, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/get-profile-list", req);
  }

  async createTextPost(
    req: CreateTextPostClientArgs
  ): Promise<AppEnvelope<TErrorHandling, SocialPost, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/create-text", req);
  }

  async deletePost(req: DeletePostArgs): Promise<AppEnvelope<TErrorHandling, {}, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/delete", req);
  }

  async likeUnlikePost(req: LikeUnlikePostArgs): Promise<AppEnvelope<TErrorHandling, SocialPost, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/like", req);
  }

  async addCommentToPost(req: AddCommentArgs): Promise<AppEnvelope<TErrorHandling, SocialPost, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/add-comment", req);
  }

  async deleteCommentFromPost(
    req: DeleteCommentArgs
  ): Promise<AppEnvelope<TErrorHandling, SocialPost, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/delete-comment", req);
  }

  async saveRecipeFromPost(
    req: SaveRecipeFromPostArgs
  ): Promise<AppEnvelope<TErrorHandling, SaveRecipeFromPostResult, SocialErrorTypes>> {
    return this.post("gateway", "/social/posts/save-recipe-from-post", req);
  }

  async saveRecipeFromSearch(
    req: SaveRecipeFromSearchArgs
  ): Promise<AppEnvelope<TErrorHandling, SaveRecipeFromSearchResult>> {
    return this.post("gateway", "/search/save-recipe", req);
  }

  async followUnfollow(req: FollowUnfollowEntityArgs): Promise<AppEnvelope<TErrorHandling, {}, SocialErrorTypes>> {
    return this.post("gateway", "/social/followers/follow", req);
  }

  async getFollowers(req: GetFollowersArgs): Promise<AppEnvelope<TErrorHandling, EntityFollowers, SocialErrorTypes>> {
    return this.post("gateway", "/social/followers/get-followers", req);
  }

  async getFollowing(req: GetFollowingArgs): Promise<AppEnvelope<TErrorHandling, UserFollowing, SocialErrorTypes>> {
    return this.post("gateway", "/social/followers/get-following", req);
  }

  async getFollowingIds(): Promise<AppEnvelope<TErrorHandling, UserFollowingInfoAndRecommendations, SocialErrorTypes>> {
    return this.post("gateway", "/social/followers/following-ids", {});
  }

  async reportDismissedFollowRecommendation(
    args: ReportDismissedFollowRecommendationArgs
  ): Promise<AppEnvelope<TErrorHandling, {}, SocialErrorTypes>> {
    return this.post("gateway", "/social/followers/dismiss-follow-recommendation", args);
  }

  async getKnownAuthorProfile(
    args: GetKnownAuthorProfileArgs
  ): Promise<AppEnvelope<TErrorHandling, KnownAuthorProfileInfo, SocialErrorTypes>> {
    return this.post("gateway", "/social/known-author-profile", args);
  }

  async getKnownPublisherProfile(
    args: GetKnownPublisherProfileArgs
  ): Promise<AppEnvelope<TErrorHandling, KnownPublisherProfileInfo, SocialErrorTypes>> {
    return this.post("gateway", "/social/known-publisher-profile", args);
  }

  async getAllKnownEntities(): Promise<AppEnvelope<TErrorHandling, KnownEntities, RecipeErrorTypes>> {
    return this.post("gateway", "/recipes/get-known", {});
  }

  async socialTestHooks(args: SocialTestHooksArgs): Promise<AppEnvelope<TErrorHandling, {}, SocialErrorTypes>> {
    if (CurrentEnvironment.configEnvironment() === "prod") {
      throw new Error("Can't call socialTestHooks in prod");
    }

    return this.post("gateway", "/social/test-hooks", args);
  }

  async storeAppState(req: StoreAppStateArgs): Promise<AppEnvelope<TErrorHandling, {}, UserErrorTypes>> {
    return this.post("gateway", "/user/diagnostics/store-app-state", req);
  }

  async reportIssue(req: ReportIssueData): Promise<AppEnvelope<TErrorHandling, void>> {
    return this.post("gateway", "/composite/report-issue", req);
  }

  async search(req: SearchArgs): Promise<AppEnvelope<TErrorHandling, SearchResults>> {
    return this.post("gateway", "/search/results", req);
  }

  async getSearchSuggestions(req: SearchSuggestionArgs): Promise<AppEnvelope<TErrorHandling, SearchSuggestions>> {
    return this.post("gateway", "/search/suggestions", req);
  }

  async getUploadPhotoRequestInfo(
    req: S3UploadAddPhotoArgs
  ): Promise<AppEnvelope<TErrorHandling, UploadPhotoRequestInfo, PhotoErrorTypes>> {
    return this.post("photos", "/get-upload-url", req);
  }

  async deletePhoto(req: DeletePhotoArgs): Promise<AppEnvelope<TErrorHandling, void, PhotoErrorTypes>> {
    return this.post("photos", "/delete", req);
  }
}
