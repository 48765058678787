import React from "react";
import IconBookRaw from "../assets/icon_book.svg";
import IconCartRaw from "../assets/icon_cart.svg";
import IconChevronLeftRaw from "../assets/icon_chevron_left.svg";
import IconChevronRightRaw from "../assets/icon_chevron_right.svg";
import IconExRaw from "../assets/icon_ex.svg";
import IconHomeRaw from "../assets/icon_home.svg";
import IconPlusRaw from "../assets/icon_plus.svg";
import IconMinusRaw from "../assets/icon_minus.svg";
import IconMenuRaw from "../assets/icon_menu.svg";
import IconDocumentRaw from "../assets/icon_document.svg";
import IconChefHatRaw from "../assets/icon_chef_hat.svg";
import IconCircleCheckRaw from "../assets/icon_circle_check.svg";
import IconCircleCheckFilledRaw from "../assets/icon_circle_check_filled.svg";
import IconCircleExRaw from "../assets/icon_circle_ex.svg";
import IconCircleExFilledRaw from "../assets/icon_circle_ex_filled.svg";
import IconEllipsisRaw from "../assets/icon_ellipsis.svg";
import IconSearchRaw from "../assets/icon_search.svg";
import IconAddRaw from "../assets/icon_add.svg";
import IconLinkRaw from "../assets/icon_link.svg";
import IconCookPotOpenRaw from "../assets/icon_cook_pot_open.svg";
import IconCookPotRaw from "../assets/icon_cook_pot.svg";
import IconEditSquareRaw from "../assets/icon_edit_square.svg";
import IconEditRaw from "../assets/icon_edit.svg";
import IconLikeRaw from "../assets/icon_like.svg";
import IconLikeFilledRaw from "../assets/icon_like_filled.svg";
import IconCommentRaw from "../assets/icon_comment.svg";
import IconDeleteRaw from "../assets/icon_delete.svg";
import IconArchiveRaw from "../assets/icon_archive.svg";
import IconMoreRaw from "../assets/icon_more.svg";
import IconOpenLinkRaw from "../assets/icon_open_link.svg";
import IconPlayCircleRaw from "../assets/icon_play_circle.svg";
import IconPlayFilledRaw from "../assets/icon_play_filled.svg";
import IconPauseCircleRaw from "../assets/icon_pause_circle.svg";
import IconStopCircleRaw from "../assets/icon_stop_circle.svg";
import IconPauseFilledRaw from "../assets/icon_pause_filled.svg";
import IconBellRaw from "../assets/icon_bell.svg";
import IconThumbsUpRaw from "../assets/icon_thumbs_up.svg";
import IconThumbsDownRaw from "../assets/icon_thumbs_down.svg";
import IconShareRaw from "../assets/icon_share.svg";
import IconTextRaw from "../assets/icon_text.svg";
import IconRepeatRaw from "../assets/icon_repeat.svg";
import IconClockRaw from "../assets/icon_clock.svg";
import IconUsersRaw from "../assets/icon_users.svg";
import IconFilterRaw from "../assets/icon_filter.svg";
import IconLockRaw from "../assets/icon_lock.svg";
import IconCheckRaw from "../assets/icon_check.svg";
import IconEmailRaw from "../assets/icon_email.svg";
import IconSpeakerOnRaw from "../assets/icon_speaker_on.svg";
import IconSpeakerMutedRaw from "../assets/icon_speaker_muted.svg";
import IconUserCircleRaw from "../assets/icon_user_circle.svg";
import IconTagRaw from "../assets/icon_tag.svg";
import IconNoteRaw from "../assets/icon_note.svg";
import IconReloadRaw from "../assets/icon_refresh.svg";
import IconSettingsRaw from "../assets/icon_settings.svg";
import IconAddUserRaw from "../assets/icon_add_user.svg";
import IconFlagRaw from "../assets/icon_flag.svg";
import IconOpenExternalRaw from "../assets/icon_open_external.svg";
import IconStarFilledRaw from "../assets/icon_star_filled.svg";
import IconStarRaw from "../assets/icon_star.svg";
import IconArrowUpLeftRaw from "../assets/icon_arrow_up_left.svg";
import IconVerifiedRaw from "../assets/icon_verified.svg";
import { globalStyleColors, Opacity } from "./GlobalStyles";
import { NumberProp, SvgProps } from "react-native-svg";
import { ColorValue, DimensionValue, View } from "react-native";
import { Badge } from "./Badge";

/*******************************************************************************************************************
 * IMPORTANT
 * ICONS SHOULD COME FROM https://feathericons.com/
 *
 ******************************************************************************************************************/

const constants = {
  iconSizeDefault: 24,
  badgeSizeDefault: 18,
};

export interface IconProps {
  size?: NumberProp;
  color?: ColorValue;
  opacity?: keyof typeof Opacity;
  strokeWidth?: number;
}

interface BadgeProps {
  // all lower case because we were getting a react web warning about the name needing to be lowercased
  badgeCount: number;
}

type IconRawProps = SvgProps;

function getIconPropsDefault<T = {}>(props: IconProps & T): IconRawProps & T {
  const size = props.size ?? constants.iconSizeDefault;
  const strokeWidth = props.strokeWidth ?? 1.2;
  const color = props.color ?? "black";
  const opacity = props.opacity ? Opacity[props.opacity] : Opacity.light;

  return {
    ...props,
    width: size,
    height: size,
    color,
    opacity,
    strokeWidth,
  };
}

function withBadge<TProps extends IconRawProps & BadgeProps>(iconType: React.FunctionComponent<TProps>, props: TProps) {
  const iconSize =
    typeof props.height === "number"
      ? props.height
      : typeof props.width === "number"
      ? props.width
      : constants.iconSizeDefault;
  const iconScale = iconSize / constants.iconSizeDefault;
  const badgeScale = Math.min(iconScale, 2);
  const badgeOffset = iconScale > 2 ? iconScale : badgeScale;

  const icon = React.createElement(iconType, props);
  const badge = !!props.badgeCount && props.badgeCount > 0 && (
    <Badge count={props.badgeCount} scale={badgeScale} offset={badgeOffset} />
  );

  return (
    <View>
      {icon}
      {badge}
    </View>
  );
}

export const IconHome = React.memo((props: IconProps & BadgeProps) => {
  return withBadge(IconHomeRaw, getIconPropsDefault(props));
});

export const IconBook = React.memo((props: IconProps) => {
  return <IconBookRaw {...getIconPropsDefault(props)} />;
});

export const IconCart = React.memo((props: IconProps) => {
  return <IconCartRaw {...getIconPropsDefault(props)} />;
});

export const IconMenu = React.memo((props: IconProps) => {
  return <IconMenuRaw {...getIconPropsDefault(props)} />;
});

export const IconChevronRight = React.memo((props: IconProps) => {
  return <IconChevronRightRaw {...getIconPropsDefault(props)} />;
});

export const IconChevronLeft = React.memo((props: IconProps) => {
  return <IconChevronLeftRaw {...getIconPropsDefault(props)} />;
});

export const IconPlus = React.memo((props: IconProps) => {
  return <IconPlusRaw {...getIconPropsDefault(props)} />;
});

export const IconMinus = React.memo((props: IconProps) => {
  return <IconMinusRaw {...getIconPropsDefault(props)} />;
});

export const IconEx = React.memo((props: IconProps) => {
  return <IconExRaw {...getIconPropsDefault(props)} />;
});

export const IconDocument = React.memo((props: IconProps) => {
  return <IconDocumentRaw {...getIconPropsDefault(props)} />;
});

export const IconChefHat = React.memo((props: IconProps) => {
  return <IconChefHatRaw {...getIconPropsDefault(props)} />;
});

export const IconEmptyCircle = React.memo((props: IconProps) => {
  const { width, height, opacity: opacityDefault } = getIconPropsDefault(props);
  const borderRadius = typeof width === "number" ? width / 2 : undefined;
  const opacity = typeof opacityDefault === "number" ? opacityDefault : undefined;

  return (
    <View
      style={{
        width: width as DimensionValue,
        height: height as DimensionValue,
        borderRadius,
        borderWidth: 1,
        opacity,
      }}
    />
  );
});

export const IconCircleCheckFilled = React.memo((props: IconProps) => {
  const color = props.color ?? globalStyleColors.colorAccentCool;
  const { width, height } = getIconPropsDefault(props);
  return <IconCircleCheckFilledRaw width={width} height={height} color={color} opacity={props.opacity} />;
});

export const IconCircleCheck = React.memo((props: IconProps) => {
  const color = props.color ?? globalStyleColors.colorAccentCool;
  const { width, height } = getIconPropsDefault(props);
  return <IconCircleCheckRaw width={width} height={height} color={color} opacity={props.opacity} />;
});

export const IconCircleExFilled = React.memo((props: IconProps) => {
  const color = props.color ?? globalStyleColors.colorAccentWarm;
  const { width, height } = getIconPropsDefault(props);
  return <IconCircleExFilledRaw width={width} height={height} color={color} opacity={props.opacity} />;
});

export const IconCircleEx = React.memo((props: IconProps) => {
  const color = props.color ?? globalStyleColors.colorAccentWarm;
  const { width, height } = getIconPropsDefault(props);
  return <IconCircleExRaw width={width} height={height} color={color} opacity={props.opacity} />;
});

export const IconEllipsis = React.memo((props: IconProps) => {
  const color = props.color ?? globalStyleColors.colorAccentCool;
  const { width, height } = getIconPropsDefault(props);
  return <IconEllipsisRaw width={width} height={height} color={color} opacity={props.opacity} />;
});

export const IconPlayCircle = React.memo((props: IconProps) => {
  return <IconPlayCircleRaw {...getIconPropsDefault(props)} />;
});

export const IconPlayFilled = React.memo((props: IconProps) => {
  return <IconPlayFilledRaw {...getIconPropsDefault(props)} />;
});

export const IconPauseCircle = React.memo((props: IconProps) => {
  return <IconPauseCircleRaw {...getIconPropsDefault(props)} />;
});

export const IconStopCircle = React.memo((props: IconProps) => {
  return <IconStopCircleRaw {...getIconPropsDefault(props)} />;
});

export const IconPauseFilled = React.memo((props: IconProps) => {
  return <IconPauseFilledRaw {...getIconPropsDefault(props)} />;
});

export const IconRepeat = React.memo((props: IconProps) => {
  return <IconRepeatRaw {...getIconPropsDefault(props)} />;
});

export const IconSearch = React.memo((props: IconProps) => {
  return <IconSearchRaw {...getIconPropsDefault(props)} />;
});

export const IconAdd = React.memo((props: IconProps) => {
  return <IconAddRaw {...getIconPropsDefault(props)} />;
});

export const IconLink = React.memo((props: IconProps) => {
  return <IconLinkRaw {...getIconPropsDefault(props)} />;
});

export const IconCookPotOpen = React.memo((props: IconProps) => {
  return <IconCookPotOpenRaw {...getIconPropsDefault(props)} />;
});

export const IconCookPot = React.memo((props: IconProps) => {
  return <IconCookPotRaw {...getIconPropsDefault(props)} />;
});

export const IconEditSquare = React.memo((props: IconProps) => {
  return <IconEditSquareRaw {...getIconPropsDefault(props)} />;
});

export const IconEdit = React.memo((props: IconProps) => {
  return <IconEditRaw {...getIconPropsDefault(props)} />;
});

export const IconDelete = React.memo((props: IconProps) => {
  return <IconDeleteRaw {...getIconPropsDefault(props)} />;
});

export const IconArchive = React.memo((props: IconProps) => {
  return <IconArchiveRaw {...getIconPropsDefault(props)} />;
});

export const IconLike = React.memo((props: IconProps) => {
  return <IconLikeRaw {...getIconPropsDefault(props)} />;
});

export const IconLikeFilled = React.memo((props: IconProps) => {
  const color = props.color ?? globalStyleColors.colorAccentWarm;
  const { width, height } = getIconPropsDefault(props);
  return <IconLikeFilledRaw width={width} height={height} color={color} opacity={props.opacity} />;
});

export const IconComment = React.memo((props: IconProps) => {
  return <IconCommentRaw {...getIconPropsDefault(props)} />;
});

export const IconMore = React.memo((props: IconProps) => {
  return <IconMoreRaw {...getIconPropsDefault(props)} />;
});

export const IconOpenLink = React.memo((props: IconProps) => {
  return <IconOpenLinkRaw {...getIconPropsDefault(props)} />;
});

export const IconBell = React.memo((props: IconProps) => {
  return <IconBellRaw {...getIconPropsDefault(props)} />;
});

export const IconThumbsUp = React.memo((props: IconProps) => {
  return <IconThumbsUpRaw {...getIconPropsDefault(props)} />;
});

export const IconThumbsDown = React.memo((props: IconProps) => {
  return <IconThumbsDownRaw {...getIconPropsDefault(props)} />;
});

export const IconBellFilled = React.memo((props: IconProps & BadgeProps) => {
  const color = props.color ?? globalStyleColors.colorAccentCool;
  const iconProps: IconRawProps & BadgeProps = {
    ...getIconPropsDefault(props),
    opacity: 1,
    color,
    fill: color,
    badgeCount: props.badgeCount,
  };

  return withBadge(IconBellRaw, iconProps);
});

export const IconShare = React.memo((props: IconProps) => {
  return <IconShareRaw {...getIconPropsDefault(props)} />;
});

export const IconText = React.memo((props: IconProps) => {
  return <IconTextRaw {...getIconPropsDefault(props)} />;
});

export const IconClock = React.memo((props: IconProps) => {
  return <IconClockRaw {...getIconPropsDefault(props)} />;
});

export const IconUsers = React.memo((props: IconProps) => {
  return <IconUsersRaw {...getIconPropsDefault(props)} />;
});

export const IconFilter = React.memo((props: IconProps) => {
  return <IconFilterRaw {...getIconPropsDefault(props)} />;
});

export const IconLock = React.memo((props: IconProps) => {
  return <IconLockRaw {...getIconPropsDefault(props)} />;
});

export const IconCheck = React.memo((props: IconProps) => {
  return <IconCheckRaw {...getIconPropsDefault(props)} />;
});

export const IconEmail = React.memo((props: IconProps) => {
  return <IconEmailRaw {...getIconPropsDefault(props)} />;
});

export const IconUserCircle = React.memo((props: IconProps) => {
  return <IconUserCircleRaw {...getIconPropsDefault(props)} />;
});

export const IconSpeakerOn = React.memo((props: IconProps) => {
  return <IconSpeakerOnRaw {...getIconPropsDefault(props)} />;
});

export const IconSpeakerMuted = React.memo((props: IconProps) => {
  return <IconSpeakerMutedRaw {...getIconPropsDefault(props)} />;
});

export const IconTag = React.memo((props: IconProps) => {
  return <IconTagRaw {...getIconPropsDefault(props)} />;
});

export const IconNote = React.memo((props: IconProps) => {
  return <IconNoteRaw {...getIconPropsDefault(props)} />;
});

export const IconReload = React.memo((props: IconProps) => {
  return <IconReloadRaw {...getIconPropsDefault(props)} />;
});

export const IconSettings = React.memo((props: IconProps) => {
  return <IconSettingsRaw {...getIconPropsDefault(props)} />;
});

export const IconAddUser = React.memo((props: IconProps) => {
  return <IconAddUserRaw {...getIconPropsDefault(props)} />;
});

export const IconFlag = React.memo((props: IconProps) => {
  return <IconFlagRaw {...getIconPropsDefault(props)} />;
});

export const IconOpenExternal = React.memo((props: IconProps) => {
  return <IconOpenExternalRaw {...getIconPropsDefault(props)} />;
});

export const IconStar = React.memo((props: IconProps) => {
  return <IconStarRaw {...getIconPropsDefault(props)} />;
});

export const IconStarFilled = React.memo((props: IconProps) => {
  return <IconStarFilledRaw {...getIconPropsDefault(props)} />;
});

export const IconArrowUpLeft = React.memo((props: IconProps) => {
  return <IconArrowUpLeftRaw {...getIconPropsDefault(props)} />;
});

export const IconVerified = React.memo((props: IconProps) => {
  return <IconVerifiedRaw {...getIconPropsDefault(props)} />;
});
