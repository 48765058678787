import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView, useScreenElementDimensions } from "../components/ScreenView";
import { useDispatch } from "../lib/redux/Redux";
import { loadRecipes, recipeLibraryFiltersChanged, searchRecipeLibrary } from "../lib/recipes/RecipesThunks";
import {
  Animated,
  LayoutAnimation,
  SectionList,
  SectionListData,
  SectionListProps,
  SectionListRenderItem,
  StyleSheet,
  View,
} from "react-native";
import { navTree, RecipesHomeScreenProps } from "../navigation/NavTree";
import { AddItemButton } from "../components/Buttons";
import { Spacer } from "../components/Spacer";
import { getPullToRefresh } from "../components/PullToRefresh";
import { useTabRenderDelay } from "../lib/util/UseTabRenderDelay";
import {
  RecipeListItem,
  RecipeListSections,
  useFilteredRecipeListSections,
  useRecipeSearchPhrase,
} from "../lib/composite/RecipeListSelectors";
import { SectionHeading } from "../components/SectionHeading";
import { useScrollToTop } from "@react-navigation/native";
import {
  recipeCardConstants,
  RecipeCard,
  RecipeLibraryCardLoading,
  UserRecipePressedHandler,
} from "../components/recipes/RecipeCards";
import { UserRecipeId } from "@eatbetter/recipes-shared";
import { TBody, THeading2 } from "../components/Typography";
import Reanimated, { useAnimatedScrollHandler } from "react-native-reanimated";
import { useRecipe } from "../lib/recipes/RecipesSelectors";
import { HeaderProps, useHeaderScrollAnimation } from "../components/ScreenHeaders";
import {
  AppRecipeTag,
  highlightLibraryAddButtonChanged,
  removeAllLibraryFilters,
  removeTagFromLibraryFilter,
} from "../lib/recipes/RecipesSlice";
import { useFilterTags } from "../lib/recipes/RecipeTagSelectors";
import { Haptics } from "../components/Haptics";
import { SearchAndFilterBar, useSearchAndFilterBarHeight } from "../components/SearchBox";
import { useCheckpointCompleted } from "../lib/system/SystemSelectors";
import { useBottomSheet } from "./BottomSheetScreen";
import { OptionsMenu, OptionsMenuItem } from "../components/OptionsMenu";
import { useWobbleAnimation } from "../components/AttentionGrabbers";
import { useResponsiveDimensions } from "../components/Responsive";
import { useRecipeLibraryHint } from "../lib/recipes/UserRecipeLibraryHint";
import { reportAddRecipeButtonTapped, reportLibraryAddFilterButtonPressed } from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { spotlight } from "../components/Spotlight";
import { tryParseBool } from "@eatbetter/common-shared";
import { maybePromptForReview } from "../lib/system/SystemThunks";
import { useAnonymousRedirectCallback } from "../lib/util/AnonymousSignIn";
import { globalStyleColors } from "../components/GlobalStyles";
import { ShareExtensionDemoVideo } from "./OnboardShareExtensionScreen";
import { navToCookingSessionIfExists } from "../navigation/NavThunks";
import { SearchSessionId } from "../lib/search/SearchSlice";

const strings = {
  header: "My Recipes",
  emptyState: {
    headline: "Save any recipe right\nfrom your browser",
    subhead: "",
    dontSeeDeglaze: "Don't see Deglaze?",
  },
  setupShareExtension: {
    headline: "💡 Save any recipe to your library right\nfrom your browser",
    subhead: "",
    buttonText: "Show me",
  },
  noResults: "No results.",
  sections: {
    cooking: "Cooking in Progress",
    shopped: "Recently Shopped",
    other: "All Recipes",
  },
  searchPlaceholder: "Search your library",
  addMenu: {
    addFromUrl: "Save a recipe from the web",
    addManual: "Enter your own recipe",
  },
  addManualAction: "add your own recipe",
};

const config = {
  sheetHeight: 148,
  onboardingHint: {
    delayMs: 1000,
    durationMs: 1600,
    buttonWobbleDelayMs: 2800,
  },
};

const AnimatedSectionList =
  Reanimated.createAnimatedComponent<SectionListProps<RecipeListItem, { title: string }>>(SectionList);

type ScrollEventHandler = ReturnType<typeof useAnimatedScrollHandler>;

export const RecipesHomeScreen = withScreenContainer(
  "RecipesHomeScreen",
  (props: RecipesHomeScreenProps) => {
    const dispatch = useDispatch();
    const screen = useScreen();
    const sections = useFilteredRecipeListSections();
    const shouldRender = useTabRenderDelay(500, screen.nav.focused);

    const searchPhrase = useRecipeSearchPhrase() ?? "";
    const alreadyPromptedForReview = useRef(false);

    useEffect(() => {
      if (props.cookingSessionJustSaved && screen.nav.focused && !alreadyPromptedForReview.current) {
        alreadyPromptedForReview.current = true;
        // wait for any navigation animation to end - tested on a device and this timing seems to work well
        setTimeout(() => {
          dispatch(maybePromptForReview("Cooking Session Saved"));
        }, 500);
      }
    }, [props.cookingSessionJustSaved, dispatch, screen.nav.focused, alreadyPromptedForReview]);

    const setSearchPhrase = useCallback(
      (s: string) => {
        // I tried debouncing by using a state variable to set the display text
        // and then use a debounced function called from the change handler to update
        // redux. Performance was better without the debounce. The lagginess in the sim
        // is much less of an issue on device, even with 1000 recipes.
        dispatch(searchRecipeLibrary({ query: s }));
      },
      [dispatch]
    );

    const onPullToRefresh = useCallback(() => {
      return dispatch(loadRecipes("full"));
    }, [dispatch]);

    return React.createElement(RecipesHomeScreenComponent, {
      shouldRender,
      sections,
      searchPhrase,
      setSearchPhrase,
      onPullToRefresh,
    });
  },
  {
    serializer: {
      cookingSessionJustSaved: { optional: true, fn: b => (b ? "1" : "0") },
    },
    parser: {
      cookingSessionJustSaved: { optional: true, fn: b => tryParseBool(b) ?? false },
    },
  }
);

interface Props {
  shouldRender: boolean;
  sections: RecipeListSections;
  searchPhrase: string;
  setSearchPhrase: (str: string) => void;
  onPullToRefresh: () => Promise<void>;
}

export const RecipesHomeScreenComponent = (props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const recipeSectionListRef = useRef<RecipeSectionListImperativeHandle>(null);

  const onRecipeSelected = useCallback<UserRecipePressedHandler>(
    recipeId => {
      if (!dispatch(navToCookingSessionIfExists({ type: "library", nav: screen.nav.goTo, recipeId }))) {
        screen.nav.goTo("push", navTree.get.screens.recipeDetail, { recipeId: recipeId as UserRecipeId });
      }
    },
    [dispatch, screen.nav.goTo]
  );

  const onPressAddItem = useCallback(() => {
    dispatch(analyticsEvent(reportAddRecipeButtonTapped()));
    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: <RecipeAddMenu />,
      height: config.sheetHeight,
    });
  }, [screen.nav.modal]);

  const [headerAnimationProgress, onScroll] = useHeaderScrollAnimation();

  // FILTERS

  const activeTagFilters = useFilterTags(undefined);
  const hasActiveSearchFilter = !!props.searchPhrase;
  const hasActiveTagFilters = activeTagFilters.length > 0;
  const searchAndFilterBarHeight = useSearchAndFilterBarHeight(activeTagFilters);

  const onPressFilterButton = useCallback(() => {
    dispatch(analyticsEvent(reportLibraryAddFilterButtonPressed()));
    screen.nav.modal(navTree.get.screens.recipesFilter, { searchSessionId: undefined });
    setTimeout(() => recipeSectionListRef.current?.scrollToTop({ animated: false }), 500);
  }, [dispatch, screen.nav.modal, recipeSectionListRef]);

  const removeFilter = useCallback(
    (tag: AppRecipeTag) => {
      Haptics.feedback("itemDeleted");
      recipeSectionListRef.current?.scrollToTop();
      dispatch(removeTagFromLibraryFilter(tag));
      dispatch(recipeLibraryFiltersChanged());
    },
    [dispatch, recipeSectionListRef]
  );

  const onPressClearSearchAndFilters = useCallback(() => {
    Haptics.feedback("itemDeleted");
    recipeSectionListRef.current?.scrollToTop();
    dispatch(removeAllLibraryFilters());
  }, [dispatch, recipeSectionListRef]);

  const onChangeSearchPhrase = useCallback(
    (value: string) => {
      recipeSectionListRef.current?.scrollToTop();
      props.setSearchPhrase(value);
    },
    [recipeSectionListRef, props.searchPhrase]
  );

  const noResults = Object.values(props.sections).every(i => i.length === 0);
  const emptyState = !hasActiveTagFilters && !hasActiveSearchFilter && noResults;
  const showShareExtensionSetup = !useCheckpointCompleted("shareExtensionUsed") && !emptyState;

  const renderSearchAndFilterBar = useCallback(() => {
    if (emptyState) {
      return null;
    }

    return (
      <SearchAndFilterBar
        placeholderText={strings.searchPlaceholder}
        searchPhrase={props.searchPhrase}
        onChangeSearchPhrase={onChangeSearchPhrase}
        onPressFilterButton={onPressFilterButton}
        activeTagFilters={activeTagFilters}
        onRemoveFilter={removeFilter}
        showCancelButton={hasActiveTagFilters}
        onPressCancel={onPressClearSearchAndFilters}
      />
    );
  }, [
    emptyState,
    props.searchPhrase,
    onChangeSearchPhrase,
    onPressFilterButton,
    hasActiveTagFilters,
    activeTagFilters,
    removeFilter,
    onPressClearSearchAndFilters,
  ]);

  const getSearchAndFilterBarHeight = useCallback(() => {
    if (emptyState) {
      return 0;
    }
    return searchAndFilterBarHeight;
  }, [emptyState, searchAndFilterBarHeight]);

  // Animate layout changes
  useEffect(() => {
    if (!screen.nav.focused) {
      return;
    }

    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [props.sections, activeTagFilters]);

  const { width: screenWidth } = useResponsiveDimensions();
  const { headerHeight: bareHeaderHeight } = useScreenElementDimensions();
  const headerHeight = bareHeaderHeight + getSearchAndFilterBarHeight() + 12;

  const onboardingHint = useRecipeLibraryHint();

  // Onboarding
  useEffect(() => {
    if (onboardingHint === "highlightAddButton") {
      // [AA] Removing spotlight 12/20 v1.7 in favor of bringing share extension front and center in recipe home
      // spotlight add recipe button if user has not added recipe yet
      // const buttonMargin = 24;
      // const buttonSize = buttonCirclePlusSize + 2 * buttonMargin;
      // const spotlightPadding = -4;

      // spotlight({
      //   delayMs: config.onboardingHint.delayMs,
      //   durationMs: config.onboardingHint.durationMs,
      //   screenX: screenWidth - buttonSize - spotlightPadding,
      //   screenY: screenHeight - bottomTabBarHeight - buttonSize - spotlightPadding,
      //   width: buttonSize + 2 * spotlightPadding,
      //   height: buttonSize + 2 * spotlightPadding,
      //   analyticsEvent: "spotlightAddRecipeButton",
      // });

      // We set this when a user taps the notification about adding a web recipe so that the `useRecipeLibraryHint` hook knows
      // to prioritize that hint so once we’ve done it, we want to unset it. Otherwise, that will always be the hint displayed.
      dispatch(highlightLibraryAddButtonChanged(false));
    } else if (onboardingHint === "highlightRecipe") {
      // spotlight first added recipe if user has not opened it yet
      const spotlightPaddingX = 56;
      const spotlightPaddingY = 12;

      spotlight({
        delayMs: config.onboardingHint.delayMs,
        durationMs: config.onboardingHint.durationMs,
        screenX: 0 - spotlightPaddingX,
        screenY: headerHeight - spotlightPaddingY,
        width: screenWidth + 2 * spotlightPaddingX,
        height: recipeCardConstants.verticalScrollCardHeight + 2 * spotlightPaddingY,
        analyticsEvent: "spotlightLibraryRecipe",
      });
    }
  }, [onboardingHint]);

  const screenHeader = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      style: "tabRoot",
      title: strings.header,
      animationConfig: {
        animationProgress: headerAnimationProgress,
      },
      subHeaderComponent: { render: renderSearchAndFilterBar, getHeight: getSearchAndFilterBarHeight },
    };
  }, [headerAnimationProgress, renderSearchAndFilterBar, getSearchAndFilterBarHeight]);

  // -------- RENDER STARTS HERE --------

  return (
    <ScreenView
      scrollView={false}
      paddingVertical={false}
      paddingHorizontal={false}
      header={screenHeader}
      loading={!props.shouldRender}
    >
      {emptyState && <EmptyState />}
      {!emptyState && (
        <RecipeSectionList
          ref={recipeSectionListRef}
          sections={props.sections}
          onRecipeSelected={onRecipeSelected}
          onPullToRefresh={props.onPullToRefresh}
          onScroll={onScroll}
          showShareExtensionSetup={showShareExtensionSetup}
          showFirstItemTapHint={screen.nav.focused && onboardingHint === "highlightRecipe"}
        />
      )}
      <AddItemButton onPress={onPressAddItem} />
    </ScreenView>
  );
};

type RecipeSectionListData = SectionListData<RecipeListItem, { title: string }>;
interface RecipeSectionListImperativeHandle {
  scrollToTop: (opts?: { animated: boolean }) => void;
}

const RecipeSectionList = React.memo(
  React.forwardRef<
    RecipeSectionListImperativeHandle,
    Pick<Props, "sections" | "onPullToRefresh"> & {
      onRecipeSelected: UserRecipePressedHandler;
      onScroll: ScrollEventHandler;
      showShareExtensionSetup?: boolean;
      showFirstItemTapHint?: boolean;
    }
  >((props, ref) => {
    const { cookingSessionRecipes, groceryListRecipes, otherRecipes } = props.sections;
    const noResults = [cookingSessionRecipes, groceryListRecipes, otherRecipes].every(i => i.length === 0);

    const listRef = useRef<SectionList<any, any>>(null);
    useScrollToTop(listRef);

    const wobbleAnimation = useWobbleAnimation({ delay: config.onboardingHint.buttonWobbleDelayMs, loop: true });

    // Onbooarding hint: show first item tap hint if prop is set
    useEffect(() => {
      if (props.showFirstItemTapHint) {
        wobbleAnimation.startAnimation();
        return;
      }
      wobbleAnimation.stopAnimation();
    }, [props.showFirstItemTapHint]);

    useImperativeHandle(
      ref,
      () => ({
        scrollToTop: (opts?: { animated: boolean }) => {
          if (!listRef.current) {
            return;
          }
          listRef.current.scrollToLocation({
            sectionIndex: 0,
            itemIndex: 0,
            viewPosition: 1,
            animated: opts?.animated,
          });
        },
      }),
      [listRef]
    );

    const data: RecipeSectionListData[] = useMemo(
      () => [
        {
          title: strings.sections.cooking,
          data: cookingSessionRecipes,
        },
        {
          title: strings.sections.shopped,
          data: groceryListRecipes,
        },
        {
          title: strings.sections.other,
          data: otherRecipes,
        },
      ],
      [cookingSessionRecipes, groceryListRecipes, otherRecipes]
    );

    const itemHeight = recipeCardConstants.verticalScrollCardHeight;
    const itemMargin = 8;

    const renderItem: SectionListRenderItem<RecipeListItem> = useCallback(
      ({ item, index }) => {
        const leadingVerticalSpace = index !== 0 ? { marginTop: itemMargin } : {};

        const recipeListItem = (
          <View style={[{ height: itemHeight, marginHorizontal: itemMargin }, leadingVerticalSpace]}>
            <UserRecipeListItemOrSpinner
              recipeId={item.recipeId}
              index={index}
              onPress={props.onRecipeSelected}
              searchSessionId={undefined}
            />
          </View>
        );

        if (props.showFirstItemTapHint) {
          return <Animated.View style={wobbleAnimation.animatedStyle}>{recipeListItem}</Animated.View>;
        }

        return recipeListItem;
      },
      [props.onRecipeSelected, itemHeight, itemMargin]
    );

    const keyExtractor: (item: RecipeListItem, index: number) => string = useCallback(item => {
      return item.recipeId;
    }, []);

    const sectionLengths = [cookingSessionRecipes.length, groceryListRecipes.length];
    const singleCategoryMode = sectionLengths.every(i => i === 0);

    const renderSectionHeader = useCallback(
      (info: { section: RecipeSectionListData }) => {
        if (info.section.data.length === 0 || singleCategoryMode) {
          return null;
        }

        return (
          <View key={info.section.title}>
            <SectionHeading text={info.section.title} />
          </View>
        );
      },
      [singleCategoryMode]
    );

    const renderSectionFooter = useCallback(
      (info: { section: RecipeSectionListData }) => {
        if (info.section.data.length === 0 || singleCategoryMode) {
          return null;
        }

        return <Spacer vertical={1} />;
      },
      [singleCategoryMode]
    );

    const sectionSeparator = useCallback(() => {
      if (singleCategoryMode) {
        return null;
      }

      return <Spacer vertical={1} />;
    }, [singleCategoryMode]);

    const listHeader = useCallback(() => {
      return (
        <>
          {noResults && <NoResults />}
          <Spacer vertical={1} />
        </>
      );
    }, [noResults]);

    const listFooter = useCallback(() => {
      return (
        <>
          {props.showShareExtensionSetup && (
            <>
              {singleCategoryMode && <Spacer vertical={2} />}
              <View style={{ paddingHorizontal: 20 }}>
                <Spacer vertical={1} />
                <ShareExtensionSetup />
                <Spacer vertical={15} />
              </View>
            </>
          )}
          <Spacer vertical={2} />
        </>
      );
    }, [singleCategoryMode, props.showShareExtensionSetup]);

    const { bottomTabBarHeight: paddingBottom, headerHeight: screenHeaderHeight } = useScreenElementDimensions();
    const paddingTop = screenHeaderHeight;

    const contentContainerStyle = useMemo(() => {
      return { paddingBottom, paddingTop };
    }, [paddingBottom, paddingTop]);

    const refreshControl = useMemo(() => {
      return getPullToRefresh(props.onPullToRefresh, paddingTop);
    }, [props.onPullToRefresh, paddingTop]);

    return (
      <AnimatedSectionList
        ref={listRef}
        sections={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        renderSectionHeader={renderSectionHeader}
        renderSectionFooter={renderSectionFooter}
        SectionSeparatorComponent={sectionSeparator}
        ListHeaderComponent={listHeader}
        ListFooterComponent={listFooter}
        stickySectionHeadersEnabled={false}
        refreshControl={refreshControl}
        contentContainerStyle={contentContainerStyle}
        onScroll={props.onScroll}
        scrollEventThrottle={16}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      />
    );
  })
);

interface UserRecipeItemOrSpinnerProps {
  recipeId: UserRecipeId;
  index: number;
  onPress: UserRecipePressedHandler;
  searchSessionId: SearchSessionId | undefined;
}

const UserRecipeListItemOrSpinner = React.memo((props: UserRecipeItemOrSpinnerProps) => {
  const recipe = useRecipe(props.recipeId);
  if (!recipe) {
    return <RecipeLibraryCardLoading />;
  }
  return <RecipeCard {...recipe} index={props.index} onPress={props.onPress} searchSessionId={props.searchSessionId} />;
});

const RecipeAddMenu = React.memo(() => {
  const screen = useScreen();
  const bottomSheet = useBottomSheet();

  const addFromUrl = useCallback(async () => {
    bottomSheet?.closeSheetAndGoBack();
    setTimeout(() => screen.nav.goTo("push", navTree.get.screens.recipeAddFromUrl), 50);
  }, [bottomSheet, screen.nav.goTo]);

  const addManual = useAnonymousRedirectCallback(
    strings.addManualAction,
    () => {
      bottomSheet?.closeSheetAndGoBack();
      setTimeout(() => screen.nav.goTo("push", navTree.get.screens.recipeAddManual), 50);
    },
    [bottomSheet, screen.nav.goTo]
  );

  return (
    <OptionsMenu>
      <OptionsMenuItem isFirst icon="link" text={strings.addMenu.addFromUrl} onPress={addFromUrl} />
      <OptionsMenuItem icon="add" text={strings.addMenu.addManual} onPress={addManual} />
    </OptionsMenu>
  );
});

const NoResults = React.memo(() => {
  return (
    <View style={{ margin: 20 }}>
      <TBody opacity="medium">{strings.noResults}</TBody>
    </View>
  );
});

const EmptyState = React.memo(() => {
  const dispatch = useDispatch();
  const { nav } = useScreen();

  const onPressAddFirstRecipe = useCallback(() => {
    nav.modal(navTree.get.screens.onboardShareExtensionFirstTime);
  }, [dispatch]);

  return (
    <View style={styles.emptyStateContainer}>
      <THeading2 numberOfLines={2} adjustsFontSizeToFit align="center">
        {strings.emptyState.headline}
      </THeading2>
      {/* <Spacer vertical={0.5} />
      <TBody lineHeight={24} numberOfLines={1} adjustsFontSizeToFit align="center" opacity="opaque">
        {strings.emptyState.subhead}
      </TBody> */}
      <Spacer vertical={0.5} />
      <View style={{ height: "55%" }}>
        <ShareExtensionDemoVideo />
      </View>
      <Spacer vertical={1} />
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TBody
          onPress={onPressAddFirstRecipe}
          suppressHighlighting
          color={globalStyleColors.colorTextLink}
          fontWeight="medium"
        >
          {strings.emptyState.dontSeeDeglaze}
        </TBody>
      </View>
    </View>
  );
});

const ShareExtensionSetup = React.memo(() => {
  const screen = useScreen();

  const onPressHowTo = useCallback(() => {
    screen.nav.modal(navTree.get.screens.onboardShareExtension);
  }, [screen.nav.modal]);

  return (
    <>
      <TBody align="center">{strings.setupShareExtension.headline}</TBody>
      {/* <TSecondary lineHeight={24} align="center" opacity="opaque">
        {strings.setupShareExtension.subhead}
      </TSecondary> */}
      <Spacer vertical={0.5} />
      <TBody
        align="center"
        onPress={onPressHowTo}
        color={globalStyleColors.colorTextLink}
        fontWeight="medium"
        suppressHighlighting
      >
        {strings.setupShareExtension.buttonText}
      </TBody>
    </>
  );
});

const styles = StyleSheet.create({
  emptyStateContainer: {
    flex: 1,
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
