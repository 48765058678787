import { UrlString } from "@eatbetter/common-shared";
import { useSelector } from "../redux/Redux";
import { WebViewSessionId } from "./WebViewSlice";

export const useWebViewInitialUrl = (sessionId: WebViewSessionId | undefined): UrlString | undefined =>
  useSelector(s => {
    if (!sessionId) {
      return undefined;
    }
    return s.webView.sessions[sessionId]?.initialUrl;
  });

export const useWebViewCurrentUrl = (sessionId: WebViewSessionId | undefined): UrlString | undefined =>
  useSelector(s => {
    if (!sessionId) {
      return undefined;
    }
    return s.webView.sessions[sessionId]?.currentUrl;
  });

export const useWebViewCanGoBack = (sessionId: WebViewSessionId | undefined): boolean =>
  useSelector(s => {
    if (!sessionId) {
      return false;
    }
    return !!s.webView.sessions[sessionId]?.canGoBack;
  });

export const useWebViewCanGoForward = (sessionId: WebViewSessionId | undefined): boolean =>
  useSelector(s => {
    if (!sessionId) {
      return false;
    }
    return !!s.webView.sessions[sessionId]?.canGoForward;
  });

export const useWebViewLoadingProgress = (sessionId: WebViewSessionId | undefined): number =>
  useSelector(s => {
    if (!sessionId) {
      return 0;
    }
    return s.webView.sessions[sessionId]?.loadingProgress ?? 0;
  });

export const useWebViewIsNavigated = (sessionId: WebViewSessionId | undefined): boolean =>
  useSelector(s => {
    if (!sessionId) {
      return false;
    }
    const session = s.webView.sessions[sessionId];
    return !!session?.canGoBack;
  });
