import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ScreenView } from "../ScreenView";
import { RecipeDetail } from "./RecipeDetail";
import { HeaderProps, useHeaderOffset } from "../ScreenHeaders";
import { useReaderMode } from "../../lib/recipes/UseReaderMode";
import { AppRecipe, isUserRecipeId } from "@eatbetter/recipes-shared";
import { globalStyleColors } from "../GlobalStyles";
import { useDispatch } from "../../lib/redux/Redux";
import { reportRecipePaywallHit } from "../../lib/recipes/RecipesThunks";
import { displayUnexpectedErrorAndLog } from "../../lib/Errors";
import { useScreen } from "../../navigation/ScreenContainer";
import { navTree } from "../../navigation/NavTree";
import { RecipeDetailOptions, getRecipeDetailOptionsHeight } from "./RecipeDetailOptions";
import { analyticsEvent } from "../../lib/analytics/AnalyticsThunks";
import { reportRecipeLibraryContextMenuOpened } from "../../lib/analytics/AnalyticsEvents";
import { useSystemSetting } from "../../lib/system/SystemSelectors";
import { useIsOwnUserRecipe, useRecipeSourceUrl } from "../../lib/recipes/RecipesSelectors";
import { usePaywallDetection, usePaywallStatus } from "../PaywallDetector";
import { UrlString, switchReturn } from "@eatbetter/common-shared";
import { WebViewNavigationStateChangeHandler } from "../WebView";
import { RecipeDetailActionBar, useRecipeDetailActionBarHeight } from "./RecipeDetailActionBar";
import { useCookingSessionId } from "../../lib/cooking/CookingSessionsSelectors";
import { PaywallLocation } from "@eatbetter/composite-shared";
import { shareRecipeLink } from "../../lib/share/ShareThunks";
import { useWebViewSession } from "../../lib/webview/WebViewHooks";
import { useWebViewIsNavigated } from "../../lib/webview/WebViewSelectors";

const strings = {
  browsing: "Browsing",
};

interface Props {
  recipe: AppRecipe | undefined;
  onSaveRecipe?: () => Promise<void>;
  waitingSaveRecipe?: boolean;
  context: "search" | "post" | "share" | "library";
}

/**
 * This screen component merges library view recipe (RecipeDetailScreen.tsx) and non-library view recipe (ViewRecipeScreenComponent.tsx).
 * It conditionally renders screen nav bar actions (menu, share) as well as bottom bar actions (Add to Grocery, Start Cooking, Add to Library)
 * based on whether or not the recipe is in the user's library.
 */
export const RecipeDetailScreenComponent = React.memo((props: Props) => {
  const dispatch = useDispatch();
  const screen = useScreen();
  const cookingSessionId = useCookingSessionId(props.recipe?.id);
  const isOwnUserRecipe = useIsOwnUserRecipe(props.recipe);
  const externalShareEnabled = !!useSystemSetting("externalRecipeShare");

  const { readerModeEnabled, toggleReaderModeEnabled, renderReaderModeToggle } = useReaderMode(
    props.recipe,
    props.context
  );

  const userRecipe =
    props.recipe && "type" in props.recipe && props.recipe.type === "userRecipe" ? props.recipe : undefined;
  const userRecipeId = userRecipe?.id;
  const userRecipeIsArchived = !!(userRecipe?.archived || userRecipe?.deleted);
  const recipeUrl = useRecipeSourceUrl(props.recipe);

  const webViewSessionId = useWebViewSession(recipeUrl);
  const webViewIsNavigated = useWebViewIsNavigated(webViewSessionId);

  const { detectPaywallSignin } = usePaywallDetection(props.recipe);
  const paywallStatus = usePaywallStatus(props.recipe);

  const alreadyReportedPaywallHit = useRef(false);

  useEffect(() => {
    if (!alreadyReportedPaywallHit.current && paywallStatus.paywallIsUp && props.recipe) {
      dispatch(
        reportRecipePaywallHit(
          props.recipe,
          switchReturn<Props["context"], PaywallLocation>(props.context, {
            library: "Library Recipe Detail",
            post: "Post View Recipe",
            share: "Share View Recipe",
            search: "Search View Recipe",
          })
        )
      );
      alreadyReportedPaywallHit.current = true;
    }
  }, [paywallStatus.paywallIsUp, !!props.recipe]);

  const [currentWebViewUrl, setCurrentWebViewUrl] = useState(recipeUrl);

  const onWebViewNavStateChanged = useCallback<WebViewNavigationStateChangeHandler>(
    e => {
      // Keep track of the web view URL to open externally and for analytics
      setCurrentWebViewUrl(e.url as UrlString);
      detectPaywallSignin(e);
    },
    [detectPaywallSignin]
  );

  const onPressShareButton = useCallback(async () => {
    if (!props.recipe) {
      return;
    }

    if (!externalShareEnabled) {
      // this condition was added when we allowed sharing of non-library recipes. We need to update the backend and some
      // other app logic to have this work with non-library recipes, but not worthing doing at the moment since this
      // isn't even currently acessible.
      if (isUserRecipeId(props.recipe.id)) {
        screen.nav.modal(navTree.get.screens.shareRecipe, { recipeId: props.recipe.id });
      }
      return;
    }

    try {
      await dispatch(
        shareRecipeLink({
          recipe: props.recipe,
          webViewUrl: currentWebViewUrl,
          nav: screen.nav,
        })
      );
    } catch (err) {
      displayUnexpectedErrorAndLog("Error dispatching shareRecipeLink in RecipeDetailScreenComponent", err, {
        userRecipeId,
        sourceRecipeId: userRecipe?.sourceRecipeId,
      });
    }
  }, [screen.nav, externalShareEnabled, props.recipe, userRecipe?.sourceRecipeId, currentWebViewUrl, dispatch]);

  const onPressMenu = useCallback(() => {
    if (!userRecipeId) {
      displayUnexpectedErrorAndLog(
        "RecipeDetailScreenComponent.onPressMenu called but userRecipeId is falsy",
        {},
        { props }
      );
      return;
    }

    screen.nav.modal(navTree.get.screens.bottomSheet, {
      content: (
        <RecipeDetailOptions
          recipeId={userRecipeId}
          sourceRecipeId={userRecipe?.sourceRecipeId}
          openExternalUrl={currentWebViewUrl}
          nav={screen.nav}
        />
      ),
      height: getRecipeDetailOptionsHeight({ recipeSourceType: userRecipe?.source.type, isOwnUserRecipe }),
    });
    dispatch(analyticsEvent(reportRecipeLibraryContextMenuOpened()));
  }, [screen.nav.modal, userRecipeId, userRecipe?.sourceRecipeId, currentWebViewUrl, userRecipe?.source.type]);

  const { height: actionBarHeight, onChangeHeight: onChangeActionBarHeight } = useRecipeDetailActionBarHeight();
  const actionBarBorderWidth = recipeUrl && !readerModeEnabled && currentWebViewUrl === recipeUrl ? "thick" : "thin";

  const renderActionBar = useCallback(() => {
    return (
      <>
        {!!props.recipe && (
          <RecipeDetailActionBar
            webViewSessionId={webViewSessionId}
            recipe={props.recipe}
            onSaveRecipe={props.onSaveRecipe}
            waitingSaveRecipe={props.waitingSaveRecipe}
            topBorderWidth={actionBarBorderWidth}
            onChangeHeight={onChangeActionBarHeight}
            context={props.context}
          />
        )}
      </>
    );
  }, [
    webViewSessionId,
    props.recipe,
    props.onSaveRecipe,
    props.waitingSaveRecipe,
    actionBarBorderWidth,
    readerModeEnabled,
    onChangeActionBarHeight,
  ]);

  const headerAnimationRef = useHeaderOffset();

  const screenHeader = useMemo<HeaderProps>(() => {
    return {
      type: "custom",
      title: webViewIsNavigated ? strings.browsing : renderReaderModeToggle,
      right: webViewIsNavigated
        ? undefined
        : !!userRecipeId && !userRecipeIsArchived && !cookingSessionId
        ? {
            type: "twoButtons",
            left: { type: "share", onPress: onPressShareButton },
            right: { type: "menu", onPress: onPressMenu },
          }
        : {
            type: "share",
            onPress: onPressShareButton,
          },
      animationConfig: {
        animationProgress: headerAnimationRef,
        blurBackgroundThreshold: 0,
      },
    };
  }, [
    webViewIsNavigated,
    renderReaderModeToggle,
    userRecipeId,
    userRecipeIsArchived,
    cookingSessionId,
    onPressShareButton,
    onPressMenu,
    headerAnimationRef,
  ]);

  return (
    <ScreenView
      header={screenHeader}
      scrollView={false}
      paddingHorizontal={false}
      paddingVertical={false}
      backgroundColor={globalStyleColors.colorGreyLight}
      loading={!props.recipe}
    >
      {!!props.recipe && (
        <RecipeDetail
          webViewSessionId={webViewSessionId}
          isReaderMode={readerModeEnabled}
          toggleReaderMode={toggleReaderModeEnabled}
          recipe={props.recipe}
          screenHeaderAnimationRef={headerAnimationRef}
          onWebViewNavigationStateChange={onWebViewNavStateChanged}
          renderActionBar={renderActionBar}
          actionBarHeight={actionBarHeight}
        />
      )}
    </ScreenView>
  );
});
