import React, { useCallback, useMemo, useState } from "react";
import {
  Insets,
  LayoutAnimation,
  SectionList,
  SectionListData,
  SectionListRenderItem,
  StyleSheet,
  View,
} from "react-native";
import { TSecondary } from "./Typography";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";
import { IconClock, IconSearch } from "./Icons";
import { useDispatch } from "../lib/redux/Redux";
import { Spacer } from "./Spacer";
import { SearchSessionId, removeAllRecentQueries, removeRecentQuery } from "../lib/search/SearchSlice";
import { bottomThrow, filterOutFalsy, switchReturn } from "@eatbetter/common-shared";
import { Pressable } from "./Pressable";
import { AnimatedChevron } from "./AnimatedChevron";
import { Alert } from "./Alert/Alert";
import { useQuerySuggestions, useSearchInputText } from "../lib/search/SearchSelectors";
import { setSearchQueryFilter } from "../lib/search/SearchThunks";
import { IconButton } from "./Buttons";
import {
  reportDeleteAllRecentQueriesTapped,
  reportSearchRecentQueryDeleted,
  reportSearchSuggestionUpLeftArrowTapped,
  reportSeeMoreRecentQueriesTapped,
} from "../lib/analytics/AnalyticsEvents";
import { analyticsEvent } from "../lib/analytics/AnalyticsThunks";
import { useKeyboardLayoutAnimation } from "./Keyboard";
import { useScreen } from "../navigation/ScreenContainer";
import { navTree } from "../navigation/NavTree";
import { useCheckpointCompleted } from "../lib/system/SystemSelectors";
import { Separator } from "./Separator";

const strings = {
  seeMore: "See more",
  deleteAll: "Clear all",
  deleteAllAlert: {
    title: "Clear search history?",
    body: "All your search history will be deleted.",
    submit: "Clear",
  },
  followCollections: ["💡 Tip: Get personalized search results\nby following your favorite\n", "Recipe Collections"],
};

interface SearchQuerySuggestionsProps {
  searchSessionId: SearchSessionId;
  onPressItem: QuerySuggestionPressedHandler;
}

type SectionType = "recentQueries" | "querySuggestions";
interface Section {
  // TBD once there are other sections / item types
  header?: unknown;
}
export type QuerySuggestionPressedHandler = (args: { type: SectionType; value: string; index: number }) => void;

interface SectionItemBase<TType extends SectionType, TData> {
  type: TType;
  data: TData;
}
type RecentQueriesSectionListItem = SectionItemBase<"recentQueries", string>;
type QuerySuggestionsSectionListItem = SectionItemBase<"querySuggestions", string>;
type SectionListItem = RecentQueriesSectionListItem | QuerySuggestionsSectionListItem;

type SearchQuerySuggestionsData = SectionListData<SectionListItem, Section>;

type RenderSectionPart = (info: { section: SearchQuerySuggestionsData }) => React.ReactElement;
type RenderSectionPartComponent = (props: {
  highlighted: boolean;
  section: Section;
  leadingSection: Section;
  trailingSection: Section;
  leadingItem: SectionListItem;
  trailingItem: SectionListItem;
}) => React.ReactElement;
type RenderSectionItem = SectionListRenderItem<SectionListItem, Section>;
type KeyExtractor = (item: SectionListItem, index: number) => string;

export const SearchQuerySuggestions = React.memo((props: SearchQuerySuggestionsProps) => {
  const dispatch = useDispatch();
  const queryText = useSearchInputText(props.searchSessionId)?.trim();
  const { recentQueries, suggestedQueries } = useQuerySuggestions(props.searchSessionId);
  const [recentQueriesExpanded, setRecentQueriesExpanded] = useState(recentQueries.length <= 5);

  const onPressQuerySuggestion = useCallback<QuerySuggestionPressedHandler>(
    args => {
      props.onPressItem(args);
    },
    [props.onPressItem]
  );

  const onPressSeeMoreRecentQueries = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    dispatch(analyticsEvent(reportSeeMoreRecentQueriesTapped()));
    setRecentQueriesExpanded(true);
  }, [dispatch, setRecentQueriesExpanded]);

  const onPressQueryActionButton: QueryActionButtonHandler = useCallback(
    ({ type, index, suggestionText }) => {
      switch (type) {
        case "recentQueries": {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          dispatch(analyticsEvent(reportSearchRecentQueryDeleted({ suggestionText, suggestionIndex: index })));
          dispatch(removeRecentQuery(suggestionText));
          break;
        }
        case "querySuggestions": {
          dispatch(analyticsEvent(reportSearchSuggestionUpLeftArrowTapped({ suggestionText, suggestionIndex: index })));
          dispatch(setSearchQueryFilter(props.searchSessionId, suggestionText));
          break;
        }
        default:
          bottomThrow(type);
      }
    },
    [dispatch, props.searchSessionId]
  );

  const deleteAllRecentQueries = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    dispatch(analyticsEvent(reportDeleteAllRecentQueriesTapped()));
    dispatch(removeAllRecentQueries());
  }, [dispatch]);

  const onPressDeleteAllRecentQueries = useCallback(() => {
    Alert.alert(strings.deleteAllAlert.title, strings.deleteAllAlert.body, [
      {
        type: "cancel",
        onPress: () => {},
      },
      {
        type: "delete",
        text: strings.deleteAllAlert.submit,
        onPress: deleteAllRecentQueries,
      },
    ]);
  }, [deleteAllRecentQueries]);

  const sectionData: SearchQuerySuggestionsData[] = useMemo<SearchQuerySuggestionsData[]>(
    () =>
      filterOutFalsy([
        recentQueries.length > 0
          ? {
              key: "recentQueries" satisfies SectionType,
              data: recentQueries
                .slice(0, recentQueriesExpanded ? undefined : 5)
                .map(i => ({ type: "recentQueries", data: i })),
            }
          : undefined,
        suggestedQueries.length > 0
          ? {
              key: "querySuggestions" satisfies SectionType,
              header: "querySuggestionsHeader",
              data: suggestedQueries.map(i => ({ type: "querySuggestions", data: i })),
            }
          : undefined,
      ]),
    [recentQueries, recentQueriesExpanded, suggestedQueries]
  );

  const renderItem: RenderSectionItem = useCallback(
    ({ item, index }) => {
      const itemType = item.type as SectionType;

      switch (itemType) {
        case "recentQueries":
        case "querySuggestions": {
          return (
            <QuerySuggestion
              type={itemType}
              index={index}
              queryText={queryText}
              suggestionText={item.data}
              onPress={onPressQuerySuggestion}
              onPressAction={onPressQueryActionButton}
            />
          );
        }
        default:
          bottomThrow(itemType);
      }
    },
    [queryText, onPressQuerySuggestion, onPressQueryActionButton]
  );

  const keyExtractor: KeyExtractor = useCallback(item => {
    return item.data;
  }, []);

  const renderItemSeparator: RenderSectionPartComponent = useCallback(() => {
    return <Spacer vertical={2.5} />;
  }, []);

  const renderSectionSeparator: RenderSectionPartComponent = useCallback(() => {
    return <Spacer vertical={1} />;
  }, []);

  const renderSectionHeader: RenderSectionPart = useCallback(info => {
    if (!info.section.key) {
      return <></>;
    }

    const sectionKey = info.section.key as SectionType;

    switch (sectionKey) {
      case "recentQueries": {
        return <></>;
      }
      case "querySuggestions": {
        return <Spacer vertical={0.5} />;
      }
      default:
        bottomThrow(sectionKey);
    }
  }, []);

  const renderSectionFooter: RenderSectionPart = useCallback(
    info => {
      if (!info.section.key) {
        return <></>;
      }

      const sectionKey = info.section.key as SectionType;
      const itemCount = info.section.data.length;

      switch (sectionKey) {
        case "recentQueries": {
          return (
            <>
              {itemCount > 0 && !queryText && (
                <>
                  {!recentQueriesExpanded && itemCount > 0 && <SeeMoreFooter onPress={onPressSeeMoreRecentQueries} />}
                  {recentQueriesExpanded && itemCount > 0 && (
                    <DeleteAllFooter onPress={onPressDeleteAllRecentQueries} />
                  )}
                </>
              )}
            </>
          );
        }
        case "querySuggestions": {
          return <></>;
        }
        default:
          bottomThrow(sectionKey);
      }
    },
    [recentQueriesExpanded, queryText, onPressDeleteAllRecentQueries, onPressDeleteAllRecentQueries]
  );

  const renderListEmpty: RenderSectionPartComponent = useCallback(() => {
    return <ListEmpty queryText={queryText} />;
  }, [queryText]);

  const keyboardHeight = useKeyboardLayoutAnimation();

  const contentContainerStyle = useMemo(() => {
    const footerHeight = 60;
    const extraPadding = 24;

    const paddingBottom = { paddingBottom: keyboardHeight + footerHeight + extraPadding };

    return [paddingBottom];
  }, [keyboardHeight]);

  return (
    <SectionList
      sections={sectionData}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={renderItemSeparator}
      renderSectionHeader={renderSectionHeader}
      SectionSeparatorComponent={renderSectionSeparator}
      renderSectionFooter={renderSectionFooter}
      ListEmptyComponent={renderListEmpty}
      keyboardShouldPersistTaps="always"
      contentContainerStyle={contentContainerStyle}
      showsVerticalScrollIndicator={false}
    />
  );
});

type QueryActionButtonHandler = (args: { type: SectionType; index: number; suggestionText: string }) => void;

const QuerySuggestion = React.memo(
  (props: {
    type: SectionType;
    index: number;
    queryText?: string;
    suggestionText: string;
    onPress: QuerySuggestionPressedHandler;
    onPressAction: QueryActionButtonHandler;
  }) => {
    const onPress = useCallback(() => {
      props.onPress({ type: props.type, index: props.index, value: props.suggestionText });
    }, [props.onPress, props.type, props.index, props.suggestionText]);

    const onPressAction = useCallback(() => {
      props.onPressAction({ type: props.type, index: props.index, suggestionText: props.suggestionText });
    }, [props.onPressAction, props.type, props.index, props.suggestionText]);

    // Format suggestion text according to the current value of queryText
    const suggestionText = (
      <>
        {(!props.queryText || !props.suggestionText.toLowerCase().startsWith(props.queryText.toLowerCase())) && (
          <TSecondary>{props.suggestionText}</TSecondary>
        )}
        {!!props.queryText && props.suggestionText.toLowerCase().startsWith(props.queryText.toLowerCase()) && (
          <TSecondary>
            <TSecondary>{props.suggestionText.slice(0, props.queryText.length)}</TSecondary>
            <TSecondary fontWeight="medium">{props.suggestionText.slice(props.queryText.length)}</TSecondary>
          </TSecondary>
        )}
      </>
    );

    const itemIcon = switchReturn(props.type, {
      recentQueries: <IconClock opacity="opaque" size={18} />,
      querySuggestions: <IconSearch opacity="opaque" size={19} />,
    });

    const hitSlop: Insets = { right: globalStyleConstants.defaultPadding, left: globalStyleConstants.defaultPadding };

    const actionButton = switchReturn(props.type, {
      recentQueries: (
        <IconButton type="close" onPress={onPressAction} size="small" opacity="medium" hitSlop={hitSlop} />
      ),
      querySuggestions: (
        <IconButton type="arrowUpLeft" onPress={onPressAction} size="small" opacity="opaque" hitSlop={hitSlop} />
      ),
    });

    return (
      <Pressable onPress={onPress} style={styles.querySuggestion}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {itemIcon}
          <Spacer horizontal={1} />
          {suggestionText}
        </View>
        <Spacer horizontal={1} />
        {actionButton}
      </Pressable>
    );
  }
);

const SeeMoreFooter = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable
      onPress={props.onPress}
      style={{
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: globalStyleConstants.unitSize,
      }}
    >
      <TSecondary align="center" fontWeight="medium" opacity="light">
        {strings.seeMore}
      </TSecondary>
      <AnimatedChevron isRotated size={20} color={globalStyleColors.rgba("black", "medium")} />
    </Pressable>
  );
});

const DeleteAllFooter = React.memo((props: { onPress: () => void }) => {
  return (
    <Pressable onPress={props.onPress} style={{ alignItems: "center", marginTop: globalStyleConstants.unitSize }}>
      <TSecondary align="center" opacity="medium">
        {strings.deleteAll}
      </TSecondary>
    </Pressable>
  );
});

const ListEmpty = React.memo((props: { queryText: string | undefined }) => {
  const screen = useScreen();
  const knownFollowExplained = useCheckpointCompleted("known_follow_explained");

  const onPress = useCallback(() => {
    screen.nav.goTo("push", navTree.get.screens.browseKnownEntities, {
      searchQuery: "",
      analyticsContext: "searchQuerySuggestions",
    });
  }, [screen.nav.goTo]);

  if (props.queryText) {
    return <></>;
  }

  const lineHeight = 24;

  return (
    <>
      {!knownFollowExplained && (
        <View style={{ marginHorizontal: globalStyleConstants.defaultPadding }}>
          <Spacer vertical={0.5} />
          <Separator orientation="row" />
          <Spacer vertical={2} />
          <View style={{ paddingRight: 18 }}>
            <TSecondary align="center" fixEmojiLineHeight>
              <TSecondary lineHeight={lineHeight}>{strings.followCollections[0]}</TSecondary>
              <TSecondary
                onPress={onPress}
                suppressHighlighting
                fontWeight="medium"
                color={globalStyleColors.colorTextLink}
                lineHeight={lineHeight}
              >
                {strings.followCollections[1]}
              </TSecondary>
            </TSecondary>
          </View>
        </View>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  querySuggestion: {
    paddingHorizontal: globalStyleConstants.defaultPadding,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
