import { userSignedOutEvent, newAppSession, appBlurred, appFocused } from "./system/SystemSlice";
import { ThunkAction, SyncThunkAction, subscribeToStoreChanges, AppDispatch } from "./redux/Redux";
import { AppState, AppStateStatus, Linking, Platform } from "react-native";
import {
  defaultTimeProvider,
  EpochMs,
  isNullOrUndefined,
  minutesBetween,
  UrlString,
  UserId,
} from "@eatbetter/common-shared";
import { displayUnexpectedErrorAndLog } from "./Errors";
import {
  anonymousSigninCompletedStorageKey,
  deepLinkReceived,
  deletedUserSignedIn,
  getUserOrHandleNewUser,
  setImpersonateUserHeader,
} from "./system/SystemThunks";
import { listsReactors } from "./lists/ListsReactors";
import { getReactorsHandler } from "./redux/Reactors";
import { loadGroceryLists } from "./lists/ListsThunks";
import { systemReactors } from "./system/SystemReactors";
import { recipesReactors } from "./recipes/RecipesReactor";
import { cookingSessionsReactors } from "./cooking/CookingSessionsReactors";
import { socialReactors } from "./social/SocialReactors";
import { getPushPermissionAndUpdateState, subscribeForPushNotifications } from "./PushNotificationThunks";
import { loadCookingSessions } from "./cooking/CookingSessionsThunks";
import { CurrentEnvironment } from "../CurrentEnvironment";
import { notificationsReactors } from "./notifications/NotificationsReactor";
import { loadNewNotifications } from "./notifications/NotificationsThunks";
import { loadRecipes } from "./recipes/RecipesThunks";
import { acknowledgeAlertingTimers, evaluateTimers } from "./cooking/CookingTimerThunks";
import { analyticsEvent } from "./analytics/AnalyticsThunks";
import { reportAppLaunched, reportFatalJavascriptError, reportUserSignedIn } from "./analytics/AnalyticsEvents";
import { subscribeToVolumeChanges } from "./Volume";
import { RootState } from "./redux/RootReducer";
import { WebsocketManager } from "./WebsocketManager";
import { debounce } from "lodash";
import { loadNewHomeFeedPosts } from "./social/SocialThunks";
import { isStructuredUserError } from "@eatbetter/users-shared";
import { log } from "../Log";

const reactors = [
  ...systemReactors,
  ...listsReactors,
  ...recipesReactors,
  ...cookingSessionsReactors,
  ...socialReactors,
  ...notificationsReactors,
];

export const appLaunched = (timeProvider = defaultTimeProvider): SyncThunkAction<() => void> => {
  return (dispatch, getState, deps) => {
    // ErrorUtils is a poorly documented global that I believe is only available for ios/android
    let errorGlobalHandlerConfigured = false;
    if (Platform.OS !== "web" && ErrorUtils) {
      log.info("Registering global error handler");
      ErrorUtils.setGlobalHandler?.((err, isFatal) => {
        if (isFatal) {
          handleFatalError(err, dispatch);
        } else {
          log.errorCaught("ErrorUtils global error handler invoked with non fatal error", err);
        }
      });
      errorGlobalHandlerConfigured = !!ErrorUtils.setGlobalHandler;
    }

    const state = getState();
    if (!state._persist?.rehydrated) {
      log.error(`Expecting state._persist.rehydrated to be true. Got ${state._persist?.rehydrated}`);
    }

    if (state.system.diagnosticModeEndTime && state.system.diagnosticModeEndTime > timeProvider()) {
      log.setRemoteLogLevel("info");
    }

    log.info("Thunk: appLaunched");
    // log this here so that it's picked up for remote logging
    log.info(`ErrorUtils.setGlobalHandler configured: ${errorGlobalHandlerConfigured}`);

    if (deps.appsFlyer) {
      deps.appsFlyer
        .init()
        .then(() => {
          log.info("Appsflyer successfully initialized");
        })
        .catch(err => {
          log.errorCaught("Error initializing AppsFlyer", err);
        });
    }

    if (deps.mixpanel) {
      deps.mixpanel
        .init()
        .then(() => {
          log.info("Mixpanel successfully initialized");
        })
        .catch(err => {
          log.errorCaught("Error initializing Mixpanel", err);
        });
    } else {
      log.info("No mixpanel deps found. Skipping initialization");
    }

    deps.timerAlarm?.init().catch(err => log.errorCaught("Error initializing audio in appLaunched", err));

    // retrieve the link the app was launched with and store
    Linking.getInitialURL()
      .then(r => {
        if (!isNullOrUndefined(r)) {
          log.info(`Found launch URL: ${r}`);
          dispatch(deepLinkReceived(r as UrlString));
        }
      })
      .catch(err => {
        log.errorCaught("Error calling Linking.getInitialUrl", err);
      });

    Linking.addEventListener("url", ({ url }) => {
      log.info(`App opened with URL: ${url}`);
      dispatch(deepLinkReceived(url as UrlString));
    });

    // We were seeing the same action dispatched multiple times in prod. We don't need the reactor to
    // run for every state change - we just need to make sure it runs quickly after a state change, or
    // set of state changes. 10 might be equivalent to zero in terms of the delay here - testing on the sim
    // showed a low fidelity and about 50ms of delay, but devices might be higher fidelity, and some basic app
    // testing seems performant, so I'm not worried about it at the moment.
    const debouncedReactorHandler = debounce(getReactorsHandler(reactors), 10, { leading: true });

    const unsubscribeReactors = subscribeToStoreChanges(debouncedReactorHandler);
    dispatch(analyticsEvent(reportAppLaunched()));
    dispatch(newAppSession({ startTime: timeProvider(), firstSession: true }));

    AppState.addEventListener("change", newState => {
      dispatch(appStateChanged(newState));
    });

    AppState.addEventListener("memoryWarning", () => {
      // we had a mixpanel event here, but it was quite noisy - it seems that
      // React Native uses these events to clean things up. Not sure this means it's
      // expected, but we were seeing it in "normal" usage.
      log.warn("Memory warning received");
    });

    // set initial app state
    dispatch(setInitialAppState());

    if (Platform.OS !== "web") {
      dispatch(subscribeForPushNotifications()).catch(err =>
        log.errorCaught("Error dispatching subscribeForPushNotifications", err)
      );
    }

    let unsubscribeVolume = () => {};
    let stopTimerTick = () => {};
    if (Platform.OS !== "web") {
      // set up the cooking timer tick handler
      const onTick = (n: number) => {
        dispatch(evaluateTimers(n as EpochMs));
      };
      stopTimerTick = () => deps.secondTimer.unregisterCallback(onTick);
      deps.secondTimer.start();
      deps.secondTimer.registerCallback(onTick);

      unsubscribeVolume = subscribeToVolumeChanges(() => {
        dispatch(acknowledgeAlertingTimers());
      });
    }

    // AUTH SHOULD COME LAST - WE USE AUTH STATUS ON WEB TO DETERMINE IF APP LAUNCHED IS COMPLETE.
    log.info("Subscribing to auth changes.");
    // Note that auth status is set to pending when hydrating redux-persist state for web
    // to make sure that the auth callback happens before a page renders.
    const unsubscribeAuth = deps.auth.subscribeToAuthChanges(userInfo => {
      if (userInfo) {
        dispatch(userAuthed(userInfo)).catch(err => log.errorCaught("Error caught in userAuthed", err));
      } else {
        dispatch(userNotAuthed()).catch(err => log.errorCaught("Error dispatching userNotAuthed", err));
      }
    });

    log.logRemote("App initialized");

    return () => {
      unsubscribeAuth();
      stopTimerTick();
      unsubscribeVolume();
      unsubscribeReactors();
    };
  };
};

export const handleFatalError = (err: unknown, dispatch: AppDispatch) => {
  let message = "<message not set>";
  let stack: string | undefined = undefined;

  try {
    message = `${err}`;
    if (err instanceof Error) {
      stack = err.stack;
    }
  } catch {
    // eat it
  }

  try {
    log.errorCaught("FATAL ERROR HANDLER INVOKED", err);
  } catch {
    // eat it
  }

  try {
    const errorEvent = reportFatalJavascriptError({
      message,
      stack,
    });
    dispatch(analyticsEvent(errorEvent));
  } catch {
    // eat it
  }

  try {
    displayUnexpectedErrorAndLog("handleFatalError", err);
  } catch {
    // eat it
  }
};

const setInitialAppState = (): SyncThunkAction<void> => {
  return (dispatch, getState, _deps) => {
    if (Platform.OS === "web") {
      return;
    }

    log.info("Thunk: setInitialAppState");

    const state = getState();
    if (state.system.appFocused !== undefined) {
      // we set appFocused to undefined in initialState and on rehydrate, so it should be undefined at this point
      log.warn(`Got initial appFocused state of ${state.system.appFocused}. Expecting undefined.`);
    }

    const current = AppState.currentState;
    if (current === "active") {
      log.info("Dispatching appFocused from setInitialAppState");
      dispatch(appFocused());
    } else {
      // this is likely possible if a user opens the app quickly and then switches away
      // but logging to see how prevalent it is. Not setting the app focused state here since
      // we don't have the blur time (althoyth it should be within a small number of seconds, so probably doesn't matter).
      // I *think* this is fine, since useAppFocused treats undefined as false, but it might be better to set to false
      log.warn(`Got initial app state of ${current}. Expecting active.`);
    }
  };
};

export const appStateChanged = (appState: AppStateStatus): SyncThunkAction<void> => {
  return (dispatch, getState, deps) => {
    if (Platform.OS === "web") {
      return;
    }

    log.info("Thunk: appStateChanged", { appState });

    const lastBlur = getState().system.session.lastBlur;
    const now = deps.time.epochMs();

    if (appState === "active") {
      dispatch(appFocused());
      // do this on focus to handle the case where the user has toggled the setting in the settings screen
      dispatch(getPushPermissionAndUpdateState()).catch(err =>
        log.errorCaught("Error calling getPushPermissionAndUpdateState in appStatechanged", err)
      );
      // when active the user should have to tap to play the next instruction
      deps.audioCookingSessionManager?.setAutoNextMode(false);
    }

    if (appState === "background") {
      dispatch(appBlurred(deps.time.epochMs()));
      // when the app isn't focused, we want now playing to just keep playing
      deps.audioCookingSessionManager?.setAutoNextMode(true);
    } else if (appState === "active" && lastBlur !== undefined) {
      const minutesSinceLastBlur = minutesBetween(lastBlur, now);

      // keep session threshold short on the sim for easier testing
      const threshold = CurrentEnvironment.debugBuild() ? 1 : 20;
      if (minutesSinceLastBlur > threshold) {
        log.info("Dispatching newAppSession");
        dispatch(newAppSession({ startTime: now, firstSession: false }));
      }
    }

    deps.timerAlarm?.handleAppStateChanged(appState).catch(err => {
      log.errorCaught("Error in timerAlarm.handleAppStateChanged", err);
    });

    const state = getState();

    if (appState === "active" && state.system.impersonateUser) {
      alert("Warning: You are impersonating a user!");
    }

    const authed = state.system.authStatus === "signedIn";

    if (authed) {
      if (appState === "active") {
        connectWebsocket(getState(), deps.websocketConnection);
        dispatch(loadGroceryLists()).catch(err =>
          log.errorCaught("Error dispatching loadGroceryLists in appStatechanged", err)
        );
        dispatch(loadCookingSessions()).catch(err =>
          log.errorCaught("Error dispatching loadCookingSessions in appStateChanged", err)
        );
        dispatch(loadNewNotifications()).catch(err => {
          log.errorCaught("Error dispatching loadNewNotifications in appStateChanged", err);
        });
        // main reason this is needed is if the user uses the share extension and then switches to the app
        // this should make sure the recipe is present
        dispatch(loadRecipes("partial")).catch(err => {
          log.errorCaught("Error dispatching loadRecipes appStateChanged", err);
        });
      } else if (appState === "background") {
        deps.websocketConnection.close();
      }
    }
  };
};

/**
 * Should be called once Firebase notifies us that the user has authed (either
 * after they actually sign in or launch the app and are already authed).
 */
const userAuthed = (args: { userId: UserId; isAnonymous: boolean }): ThunkAction<void> => {
  return async (dispatch, getState, deps) => {
    log.info("Thunk: userAuthed");
    const { userId } = args;

    const state = getState();

    if (!state.system.impersonateUser) {
      // Identify in mixpanel first for downstream events
      try {
        if (deps.mixpanel) {
          deps.mixpanel?.identify(userId);
          log.info(`Mixpanel identify called with user ID ${userId}`);
        }
      } catch (err) {
        log.errorCaught("Error while calling mixpanel.identify", err);
      }

      // once a user is authed in any fashion, they should no longer see the ability to continue
      // without an account. So,
      // 1. if you sign in anonymously once, and then sign out, you can't sign in anonymously again
      // 2. If you sign in with google/apple/email, you can't then create an anonymous account
      deps.asyncStorage.setItem(anonymousSigninCompletedStorageKey, "1").catch(err => {
        log.errorCaught("Error in createAnonymousUser set storage", err);
      });

      try {
        if (deps.firebaseAnalytics) {
          deps.firebaseAnalytics.identify(userId);
          const au = await deps.auth.getAuthProviderUserInfo();
          if (au?.email?.address) {
            deps.firebaseAnalytics.setEmail(au.email.address);
          }
        }
      } catch (err) {
        log.errorCaught("Error while calling firebaseAnalytics.identify/setEmail", err);
      }

      try {
        if (deps.appsFlyer) {
          deps.appsFlyer.identify(userId);
          const au = await deps.auth.getAuthProviderUserInfo();
          if (au?.email?.address) {
            deps.appsFlyer.setEmail(au.email.address);
          }
        }
      } catch (err) {
        log.errorCaught("Error while calling appsFlyer.identify", err);
      }
    }

    // if we have pre-auth data, the user has just explicitly signed-in (this data
    // gets cleared once the user is retreived
    if (state.system.preAuthData.signInMethod) {
      const authProviderUser = await deps.auth.getAuthProviderUserInfo();
      const event = reportUserSignedIn({
        signInMethod: state.system.preAuthData.signInMethod,
        email: authProviderUser?.email?.address,
      });
      dispatch(analyticsEvent(event));
    }

    // this is called as a floating promise from appLaunched (above),
    // so we need to catch errors and handle, which is currently only logging.
    try {
      // this will set or clear the headers for impersonation
      setImpersonateUserHeader(state.system.impersonateUser);

      await dispatch(getUserOrHandleNewUser({ userNotFoundBehavior: "createAccount" }));

      // if we don't have any social posts in redux, that means this is the first time the app has been opened
      // and no posts have been persisted yet (or there is an error case). Either way, kick off the fetching of social posts
      // so the home screen renders faster.
      // Without this, the social reactor wouldn't fire until afer we retrieve the user. This should beat any reactors
      // that rely on having a signed in user.
      // We currently don't care on web, so skip this
      if (Platform.OS !== "web") {
        dispatch(loadNewHomeFeedPosts("followingFeed")).catch(err => {
          log.errorCaught("Unexpected error loading initial home feed following posts in userAuthed", err);
        });

        dispatch(loadNewHomeFeedPosts("exploreFeed")).catch(err => {
          log.errorCaught("Unexpected error loading initial home feed explore posts in userAuthed", err);
        });
      }
    } catch (err) {
      if (isStructuredUserError(err) && err.data.code === "users/userDeleted") {
        await dispatch(deletedUserSignedIn());
        return;
      }

      log.errorCaught("Caught error in userAuthed thunk", err, {}, "warn");
      const state = getState();
      // if we have a persisted user, we can wait to fetch until later
      if (state.system.authStatus !== "signedIn" || !state.system.authedUser.data) {
        const rehydrated = state._persist.rehydrated;
        const authedUser = state.system.authedUser;
        const authStatus = state.system.authStatus;
        // displaying an error from a thunk because this call is driven from the app lifecycle, not from
        // any specific screen.
        displayUnexpectedErrorAndLog("Caught error in userAuthed thunk while retrieving user", err, {
          rehydrated,
          authedUser,
          authStatus,
        });
      } else {
        log.errorCaught(
          "Caught error in userAuthed thunk, but have a persisted user and authStatus=signedIn, so silently ignoring",
          err,
          {},
          "warn"
        );
      }
    }

    try {
      // initiate websocket connection
      connectWebsocket(getState(), deps.websocketConnection);
    } catch (err) {
      // can fail silently here since the app still works without a websocket connection
      log.errorCaught("Caught error in userAuthed thunk while establishing websocket connection", err);
    }
  };
};

/**
 * Should be called once Firebase notifies us that the user is not authed (either
 * after they sign out or launch the app and are not authed).
 */
const userNotAuthed = (): ThunkAction<void> => {
  return async (dispatch, _state, deps) => {
    log.info("Thunk: userNotAuthed");
    try {
      dispatch(userSignedOutEvent());
      deps.websocketConnection.close();
    } catch (err) {
      log.errorCaught("Caught error in userNotAuthed thunk", err);
    }
  };
};

function connectWebsocket(state: RootState, websocketManager: WebsocketManager) {
  // we currently don't have a way to impersonate on websocket connections, so don't open them when we're impersonating.
  if (state.system.impersonateUser) {
    // it shouldn't be open at this point, but no harm in closing again
    websocketManager.close();
    return;
  }

  if (state.system.authStatus === "signedIn") {
    websocketManager.connect();
  }
}
