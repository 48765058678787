import { UserRecipeId } from "@eatbetter/recipes-shared";
import { ReportRecipeIssueScreenProps, navTree } from "../navigation/NavTree";
import { useScreen, withScreenContainer } from "../navigation/ScreenContainer";
import { ScreenView } from "../components/ScreenView";
import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "../lib/redux/Redux";
import { reportIssue } from "../lib/system/SystemThunks";
import { useSurveyOptions } from "../components/SurveyOptions";
import { HeaderProps } from "../components/ScreenHeaders";
import { ScrollView } from "react-native";
import { BottomActionBar } from "../components/BottomActionBar";
import { useRecipeTitle } from "../lib/recipes/RecipesSelectors";
import { Haptics } from "../components/Haptics";
import { displayUnexpectedErrorAndLog } from "../lib/Errors";
import { TBody } from "../components/Typography";
import { Spacer } from "../components/Spacer";
import { ReportRecipeIssue } from "@eatbetter/composite-shared";

const strings = {
  screenTitle: "Report a Recipe Issue",
  headline: "If this recipe didn't import as expected, you can report it and we'll try to fix it.",
  options: ["Recipe is missing parts", "Recipe parts are incorrect", "Other"] as const,
  submit: "Submit",
  next: "Next",
  otherTextInputPlaceholder: "Describe the issue with this recipe...",
};

const surveyOptions: Array<{ key: ReportRecipeIssue["issue"]; displayText: string }> = [
  {
    key: "missingParts",
    displayText: strings.options[0],
  },
  {
    key: "incorrectParts",
    displayText: strings.options[1],
  },
  {
    key: "other",
    displayText: strings.options[2],
  },
];

export const ReportRecipeIssueScreen = withScreenContainer<ReportRecipeIssueScreenProps>(
  "ReportRecipeIssueScreen",
  props => {
    const screen = useScreen();
    const dispatch = useDispatch();
    const recipeTitle = useRecipeTitle(props.recipeId);

    const { selectedOption, renderSurveyOptions } = useSurveyOptions(surveyOptions);

    const [waiting, setWaiting] = useState(false);

    const isOtherOptionSelected = selectedOption?.index === strings.options.length - 1;

    const submit = useCallback(
      async (otherText?: string) => {
        try {
          if (!selectedOption) {
            throw new Error("ReportRecipeIssueScreen: submit() called but selectedOption is falsy");
          }

          await dispatch(
            reportIssue(
              { type: "recipeIssue", recipeId: props.recipeId, issue: selectedOption.key, otherText },
              setWaiting
            )
          );
        } catch (err) {
          displayUnexpectedErrorAndLog("ReportRecipeIssueScreen: error caught in submit()", err, {
            otherText,
            recipeId: props.recipeId,
          });

          if (otherText) {
            // Throw so we can catch and handle appropriately in the "other feedback" form
            throw err;
          }

          // Don't nav back on error
          return;
        }
        Haptics.feedback("operationSucceeded");
        screen.nav.goBack();
      },
      [dispatch, selectedOption, props.recipeId, setWaiting, screen.nav.goBack]
    );

    const onSubmitOtherFeedback = useCallback(
      async (otherText: string) => {
        await submit(otherText);
      },
      [submit]
    );

    const onSubmit = useCallback(async () => {
      if (isOtherOptionSelected) {
        screen.nav.modal(navTree.get.screens.surveyOtherOptionForm, {
          screenTitle: strings.screenTitle,
          headline: "",
          textInputPlaceholder: strings.otherTextInputPlaceholder,
          onSubmit: onSubmitOtherFeedback,
        });
        return;
      }
      await submit();
    }, [screen.nav.modal, isOtherOptionSelected, onSubmitOtherFeedback, submit]);

    const header = useMemo<HeaderProps>(() => {
      return {
        type: "default",
        title: strings.screenTitle,
      };
    }, []);

    return (
      <ScreenView header={header} scrollView={false}>
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          <TBody align="center" fontWeight="medium" numberOfLines={1}>
            {recipeTitle}
          </TBody>
          <Spacer vertical={1} />
          <TBody align="center" numberOfLines={2} adjustsFontSizeToFit>
            {strings.headline}
          </TBody>
          <Spacer vertical={2} />
          {renderSurveyOptions()}
          <Spacer vertical={2} />
        </ScrollView>
        <BottomActionBar
          primaryAction={{
            actionText: isOtherOptionSelected && !waiting ? strings.next : strings.submit,
            onPressAction: onSubmit,
            disabled: !selectedOption,
            waiting,
          }}
          containerBackgroundColor="transparent"
        />
      </ScreenView>
    );
  },
  {
    serializer: {
      recipeId: s => s,
    },
    parser: {
      recipeId: s => s as UserRecipeId,
    },
  }
);
