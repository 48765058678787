import { useSelector } from "../redux/Redux";

export const useShowPaywallStatusPill = () => {
  return useSelector(s => {
    if (!s.system.systemSettings.superAdmin) {
      return false;
    }
    return s.debug.showPaywallStatusPill;
  });
};
