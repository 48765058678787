import React, { PropsWithChildren, useEffect, useRef } from "react";
import { StyleSheet, View, ViewProps, ViewStyle, Animated, Platform } from "react-native";
import { globalStyleColors, globalStyleConstants } from "./GlobalStyles";

export const ContainerEnd = (props: PropsWithChildren<{ horizontal?: boolean; vertical?: boolean }>) => {
  const h = props.horizontal ? styles.horizontal : {};
  const v = props.vertical ? styles.vertical : {};

  return <View style={[h, v]}>{props.children}</View>;
};

export const ContainerFlexCentered = React.memo((props: PropsWithChildren<{}>) => {
  return <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>{props.children}</View>;
});

type PaddingTypes = "Top" | "Bottom" | "Left" | "Right" | "Horizontal" | "Vertical" | "Start" | "End" | "";
type ContainerPaddedProps = {
  all?: number | boolean;
  horizontal?: number | boolean;
  vertical?: number | boolean;
  left?: number | boolean;
  right?: number | boolean;
  top?: number | boolean;
  bottom?: number | boolean;
  flexDirection?: "row" | "column";
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
  align?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline";
  style?: Omit<ViewStyle, `padding${PaddingTypes}` | "flexDirection" | "alignItems" | "justifyContent">;
};

function oneIfTrue(x: boolean | number | undefined): number {
  switch (x) {
    case true:
      return 1;
    case undefined:
    case false:
      return 0;
    default:
      return x;
  }
}

export const ContainerPadded = (props: PropsWithChildren<ContainerPaddedProps>) => {
  const unit = globalStyleConstants.unitSize;
  const paddingLeft = unit * oneIfTrue(props.left ?? props.horizontal ?? props.all);
  const paddingRight = unit * oneIfTrue(props.right ?? props.horizontal ?? props.all);
  const paddingTop = unit * oneIfTrue(props.top ?? props.vertical ?? props.all);
  const paddingBottom = unit * oneIfTrue(props.bottom ?? props.vertical ?? props.all);
  const flexDirection = props.flexDirection;
  const alignItems = props.align;
  const justifyContent = props.justify;

  return (
    <View
      style={[
        { paddingLeft, paddingRight, paddingTop, paddingBottom, flexDirection, alignItems, justifyContent },
        props.style ?? {},
      ]}
    >
      {props.children}
    </View>
  );
};

export const ContainerFadeIn = React.memo(
  (props: PropsWithChildren<ViewProps> & { duration?: number; delay?: number }) => {
    const av = useRef(new Animated.Value(0)).current;

    useEffect(() => {
      Animated.timing(av, {
        toValue: 1,
        duration: props.duration ?? 200,
        delay: props.delay ?? 0,
        // This gets rid of a warning
        useNativeDriver: Platform.OS !== "web",
      }).start();
    }, []);

    return <Animated.View {...props} style={[props.style, { opacity: av }]} />;
  }
);

export const ContainerBordered = React.memo(
  (
    props: PropsWithChildren<{
      paddingHorizontal?: number;
      paddingVertical?: number;
      borderRadius?: number;
      backgroundColor?: string;
    }>
  ) => {
    const paddingHorizontal =
      typeof props.paddingHorizontal === "number" ? { paddingHorizontal: props.paddingHorizontal } : {};
    const paddingVertical = typeof props.paddingVertical === "number" ? { paddingVertical: props.paddingVertical } : {};
    const borderRadius = props.borderRadius
      ? { borderRadius: props.borderRadius }
      : { borderRadius: globalStyleConstants.unitSize };
    const backgroundColor = props.backgroundColor ? { backgroundColor: props.backgroundColor } : {};

    return (
      <View style={[styles.recipeFrame, paddingHorizontal, paddingVertical, borderRadius, backgroundColor]}>
        {props.children}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  horizontal: {
    flex: 1,
    alignItems: "flex-end",
  },
  vertical: {
    flex: 1,
    justifyContent: "flex-end",
  },
  recipeFrame: {
    padding: 1.5 * globalStyleConstants.unitSize,
    borderColor: globalStyleColors.rgba("black", "light"),
    borderWidth: 1,
  },
});
